import isPlainObject from './_isPlainObject';

export default function clone(item) {
    if (item && typeof item.copy === 'function') { // embedded models and observable lists.
        return item.copy();
    } else if (item && typeof item.slice === 'function') { // Strings and vanilla arrays
        return item.slice();
    } else if (item instanceof Function) {
        const copy = item.bind({});
        Object.keys(item).forEach(key => {
            copy[key] = item[key];
        });
        return copy;
    } else if (isPlainObject(item)) {
        return { ...item };
    } else if (typeof item === 'boolean') {
        return item.valueOf();
    } else if (typeof item === 'number') {
        return Number(item);
    }
    // Just return the item for primitives not listed here or undefined and null.
    return item;
}
