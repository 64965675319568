/* @flow */

import { EventEmitter } from '@analytics/essence';
import _ from './_';

/*::
import Entity from './Entity';
import Http from './Http';
import EntityCache from './EntityCache';
import RepositoryPlugin from './RepositoryPlugin';
*/

/*::
type PagedResponse<T> = {
  totalPages: integer,
  lastPage: integer,
  sort: string,
  firstPage: boolean,
  totalElements: integer,
  numberOfElements: integer,
  size: integer,
  number: interger,
  content: Array<T>
};
*/

export default class EntityRepository extends EventEmitter {

	/*::
		_http: Http;
		_cache: EntityCache;
		_options: Options;
		_plugins: Map;
	*/

	constructor(http /*: Http */, cache /*: EntityCache */, options /*: Options */ = {}) {
		super();
		this._http = http;
		this._cache = cache;
		this._options = options;
		this._plugins = new Map();
	}

	// You should never need to override this method. If you want to change the way to get an Entity,
	// you'll want to override _doGet
	get(entityType /*: Class<T> */, id /*: string */, config /*: Object */ = {})  /*: Promise<T> */ {
		const plugins = this._getPlugins(entityType);
		return this._doGet(entityType, id, config)
			.then((entity) => {
				const postGetHooks = () => this._getHooksFromPlugins(plugins, 'postGet', entity, config);
				return Promise.all(postGetHooks()).then(() => entity);
			});
	}

	_doGet(entityType /*: Class<T> */, id /*: string */, config /*: Object */ = {})  /*: Promise<T> */ {
		const url = config.url || entityType.endpoint(id);
		return this._http
			.get(url, this._idConfig(id, config))
			.then((response) => {
				const entity = entityType.fromJSON(response.data);
				return entity;
			});
	}

	query(entityType /*: Class<T> */, config /*: Object */ = {}) /*: Promise<Array<T>> */ {
		config = this._idConfig('', config);
		return this._http
			.get(entityType.endpoint(), config)
			.then((response) => {
				let items = Array.isArray(response) ? response : response.data;

				items = Array.isArray(items) ? items : [];

				return items.map(itemJSON => entityType.fromJSON(itemJSON));
			});
	}

	// When providing a custom limit for paging, use this endpoint
    // to get back just a page of data (and the paging envelope)
    pagedQuery(entityType /*: Class<T> */, config /*: Object */ = {}) /*: PagedResponse<T> */ {
        config = this._idConfig('', config);
        _.set(config, 'params.pagination', true);
        _.set(config, 'params.page', _.get(config, 'params.page', 0));
        _.set(config, 'params.limit', _.get(config, 'params.limit', 10));
        return this._http
            .get(entityType.endpoint(), config)
            .then((response) => {
                const pagedResponse = response.data;
                pagedResponse.content = pagedResponse.content.map(itemJSON => entityType.fromJSON(itemJSON));
                return pagedResponse;
            });
    }

	count(entityType, config = {}) {
		config = this._idConfig('', config);
		_.set(config, 'params.limit', 1);
		_.set(config, 'params.pagination', true);
		return this._http
			.get(entityType.endpoint(), config)
			.then(response => response.data.totalElements);
	}

	// You should never need to override this method. If you want to change the way an Entity
	// saves, you'll want to override _doSave
	save(entity /*: T */, config /*: Object */ = {}) /*: Promise */ {
		const plugins = this._getPlugins(entity.constructor);
		const isNew = !entity.id;
		const preSaveHooks = () => this._getHooksFromPlugins(plugins, 'preSave', entity, config, isNew);
		const postSaveHooks = () => this._getHooksFromPlugins(plugins, 'postSave', entity, config, isNew);
		return Promise.all(preSaveHooks())
			.then(() => this._doSave(entity, config))
			.then(() => Promise.all(postSaveHooks()))
			.then(() => entity);
	}

	_doSave(entity /*: T */, config /*: Object */ = {}) /*: Promise */ {

		const url = config.url || entity.constructor.endpoint();
		const method = entity.id ? 'put' : 'post';

		let data = entity.toJSON();
		if (config.propertiesToSave) {
			data = _.pick(data, config.propertiesToSave);
			delete config.propertiesToSave;
		}

		const saveInfo = {
			url: url,
			method: method,
			data: data,
		};

		const request = Object.assign({}, saveInfo, this._idConfig(entity.id || '', config));

		return this._http
			.request(request)
			.then((result) => {

				this._updateEntityFromResponse(entity, result);

				// AN-142287 - Make a copy of the entity before calling save to make
				// sure that there is no chance of ever storing the working entity
				// instance in the cache. Note: this._cache.update(entity) will add an item
				// to the cache for the first time if it doesn't exist yet. Then every
				// time that entity is saved again it will update the properties on it.
				//
				// AN-143423 - We shouldn't be making a copy of internal components because
				// they will typically be going inside a component we are working on like a
				// project. These components would be hydrated as expected from the cache
				// if we were loading up an existing component. The problem with making
				// a copy of them is that they aren't updated visually when updating title,
				// description, definition, etc.
				if (!this._cache.has(entity.constructor, entity.id) && !entity.internal) {
					this._cache.add(entity.copy());
				} else {
					this._cache.update(entity);
				}

				return entity;
			});
	}

	_updateEntityFromResponse(entity /*: Entity */, response /*: Object */) /*: void */ {
		// Update the entity id
		if (!entity.id && response.data.id) {
			entity.id = response.data.id;
		}
	}

	delete(entity /*: T */, config /*: Object */ = {}) /*: Promise */ {
		const url = config.url || entity.constructor.endpoint();
		return this._http.delete(url, this._idConfig(entity.id, config)).then(() => {
			this._cache.remove(entity);
			return entity;
		});
	}

	getWithIds(entityType /*: Class<T> */, ids /*: Array<string> */) /*: Promise<Array<T>> */ {
		const promises = ids.map(id => this.get(entityType, id, {hydrated: false}));
		return Promise.all(promises);
	}

	_idConfig(id /*: string */, config = {} /*: Object */) /*: Object */ {
		const idConfig = Object.assign({}, config);
		_.set(idConfig, 'params.id', id);
		return idConfig;
	}

	_getPlugins(entityType /*: Class<T> */) /*: RepositoryPlugin */ {
		const plugins = entityType.repositoryPlugins().map((Plugin) => {
			let pluginInstance = this._plugins.get(Plugin);
			if (!pluginInstance) {
				const options = Object.assign({}, this._options, {cache: this._cache, http: this._http});
				pluginInstance = new Plugin(options);
				this._plugins.set(Plugin, pluginInstance);
			}
			return pluginInstance;
		});
		return plugins;
	}

	_getHooksFromPlugins(plugins, hookType, ...params) {
		return _.compact(_.invoke(plugins, hookType, ...params));
	}

}
