// This method was taken from jquery isPlainObject https://gist.github.com/temp01/277432#file-jquery-1-4-2-js-L461-L483.
/* eslint no-restricted-syntax: 0, no-empty: 0 */
export default function isPlainObject(obj) {
    const toString = Object.prototype.toString;
    const hasOwnProperty = Object.prototype.hasOwnProperty;

    // Must be an Object.
    // Because of IE, we also have to check the presence of the constructor property.
    // Make sure that DOM nodes and window objects don't pass through, as well
    if (!obj || toString.call(obj) !== "[object Object]" || obj.nodeType || obj.setInterval) {
        return false;
    }

    // Not own constructor property must be Object
    if (obj.constructor
        && !hasOwnProperty.call(obj, "constructor")
        && !hasOwnProperty.call(obj.constructor.prototype, "isPrototypeOf")) {
        return false;
    }

    // Own properties are enumerated firstly, so to speed up,
    // if last one is own, then all properties are own.

    let key;
    for (key in obj) { }

    return key === undefined || hasOwnProperty.call(obj, key);
}
