/* @flow */

export default class ServiceRegistry {

   /* ::
      _services : Map;
   */

   constructor() {
      this._services = new Map();
   }

   set/*:: <T:*> */(type /*: Class<T>*/, instance /*: T */) {
      this._services.set(type, instance);
   }

   get/*::<T: *>*/(type /*: Class<T>*/) /*: T*/  {
      const service = this._services.get(type);
      if (!service) {
         throw new Error(`Service of type ${type} could not be found`);
      }
      return service;
   }
}
