/* @flow */
import axios from 'axios';
import _ from './_';
import { EventEmitter } from '@analytics/essence';

const PAGE_SIZE = 1000;
const MAX_CONCURRENT = 10;

/* ::

	class Response {
		// `data` is the response that was provided by the server
		data: any;

		// `status` is the HTTP status code from the server response
		status: number;

		// `statusText` is the HTTP status message from the server response
		statusText: string;

		// `headers` the headers that the server responded with
		headers: Object;

		// `config` is the config that was provided to `axios` for the request
		config: Config;
	}

	type Config = {

		// `url` is the server URL that will be used for the request
		url?: string,

		// `method` is the request method to be used when making the request (e.g. 'get', 'post', etc...)
		method?: string, // defaults to 'get'

		// `transformRequest` allows changes to the request data before it is sent to the server
		// This is only applicable for request methods 'PUT', 'POST', and 'PATCH'
		// The last function in the array must return a string or an ArrayBuffer
		transformRequest?: (data: any) => any,

		// `transformResponse` allows changes to the response data to be made before
		// it is passed to then/catch
		transformResponse?: (data: any) => any,

		// `headers` are custom headers to be sent, e.g. {'X-Requested-With': 'XMLHttpRequest'}
		headers?: Object,

		// `param` are the URL parameters to be sent with the request, e.g. { ID: 12345 }
		params?: Object,

		// `paramsSerializer` is an optional function in charge of serializing `params`
		// (e.g. https://www.npmjs.com/package/qs, http://api.jquery.com/jquery.param/)
		paramsSerializer?: (params: Object) => any,

		// `data` is the data to be sent as the request body
		// Only applicable for request methods 'PUT', 'POST', and 'PATCH'
		// When no `transformRequest` is set, must be a string, an ArrayBuffer or a hash
		data?: any,

		// `timeout` specifies the number of milliseconds before the request times out.
		// If the request takes longer than `timeout`, the request will be aborted.
		timeout?: number,

		// `withCredentials` indicates whether or not cross-site Access-Control requests
		// should be made using credentials
		withCredentials?: boolean, // default is false

		// `adapter` allows custom handling of requests which makes testing easier.
		// Call `resolve` or `reject` and supply a valid response (see [response docs](#response-api)).
		adapter?: (resolve: function, reject: function, config: Object) => void,

		// `responseType` indicates the type of data that the server will respond with
		// options are 'arraybuffer', 'blob', 'document', 'json', 'text'
		responseType?: string, // default is 'json'

		// `xsrfCookieName` is the name of the cookie to use as a value for xsrf token
		xsrfCookieName?: string, // default is 'XSRF-TOKEN'

		// `xsrfHeaderName` is the name of the http header that carries the xsrf token value
		xsrfHeaderName?: string, // default is 'X-XSRF-TOKEN'
	};
*/

class AjaxRequestManager {

	constructor() {
		this._cancelObjects = [];
	}

	add(id, opts = {}) {

		_.defaults(opts, {
			onCancel: () => {},
		});

		const cancelToken = this._generateCancelToken();
		const cancelObject = {
			id: id,
			cancelToken: cancelToken,
			onCancel: opts.onCancel,
		};

		this._cancelObjects.push(cancelObject);
		return cancelObject.cancelToken;
	}

	removeByToken(token) {
		this._cancelObjects.forEach((cancelObject) => {
			if (cancelObject.cancelToken.token === token) {
				cancelObject.deleted = true;
			}
		});

		this.removeDeleted();
	}

	// filterFunc can be a function or a string.
	cancelPendingRequests(filterFunc) {
		if (!_.isFunction(filterFunc)) {
			const searchStr = filterFunc;
			filterFunc = id =>  // Find matches where the the beginning of the string perfectly matches. This allows for a hierarchy. You can cancel all starting with "Project" or be more specific with  "Project > Panel2"
				id.indexOf(searchStr) === 0;
		}

		this._cancelObjects.forEach((cancelObject) => {
			if (filterFunc(cancelObject.id)) {
				cancelObject.cancelToken.cancel();
				cancelObject.deleted = true;
				cancelObject.onCancel();
			}
		});
	}

	removeDeleted() {
		this._cancelObjects = this._cancelObjects.filter(cancelObject => !cancelObject.deleted);
	}

	// Returns a cancelTokenSource
	// To Use: pass cancelTokenSource.token to the config.
	// To Cancel: call cancelTokenSource.cancel();
	// To Ignore a Cancel in the catch(e): check for if (axios.isCancel(thrown)) {console.log('Request canceled', thrown.message); }
	// More info: https://github.com/mzabriskie/axios#cancellation
	_generateCancelToken() {
		return axios.CancelToken.source();
	}


}

export default class Http extends EventEmitter {

	/* ::
		_axios: any;
		_config: Config;
	*/

	constructor(config /*: Config */ = {}) {
		super();
		this.updateConfig(config, true);
	}

	updateConfig(config /*: Config */, replace /*: boolean */) {
		if (replace) {
			this._config = config;
		} else {
			Object.assign(this._config, config);
		}
		this._axios = axios.create(this._config);
		this._axios.interceptors.response.use(response => response, (error) => {
			if (!_.get(error, 'config.suppressGlobalErrors') && !Http.isCancel(error)) {
				this.emit('error', error);
			}
			return Promise.reject(error);
		});
	}

	request(request /*: Config */) /*: Promise<Response> */ {
		return this._executeRequest(request);
	}

	get(url /*: string */, config /*: Config */ = {}) /*: Promise<Response> */  {
		return this._executeRequest({method: 'get', url: url}, config);
	}

	delete(url /*: string */, config /*: Config */ = {}) /*: Promise<Response> */  {
		return this._executeRequest({method: 'delete', url: url}, config);
	}

	head(url /*: string */, config /*: Config */ = {}) /*: Promise<Response> */  {
		return this._executeRequest({method: 'head', url: url}, config);
	}

	post(url /*: string */, data /*: ?any */, config /*: Config */ = {}) /*: Promise<Response> */  {
		return this._executeRequest({method: 'post', data: data, url: url}, config);
	}

	put(url /*: string */, data /*: ?any */, config /*: Config */ = {}) /*: Promise<Response> */  {
		return this._executeRequest({method: 'put', data: data, url: url}, config);
	}

	patch(url /*: string */, data /*: ?any */, config /*: Config */ = {}) /*: Promise<Response> */  {
		return this._executeRequest({method: 'patch', data: data, url: url}, config);
	}

	/* Private Methods */

	_executeRequest(...configs /*: Array<Config> */) /*: Promise<Response> */ {
		// Combine and fix configs
		const config = _.extend({}, ...configs);
		this._applyUrlParams(config);

		if (this.doRequestViaQueue) {
			return this.doRequestViaQueue(config);
		}

		return this._doRequest(config);
	}

	_doRequest(config /*: any */) /*: Promise<Response> */ {
		return this._axios.request(config).then((result) => {
			if (config.cancelToken) { this.ajaxRequestManager.removeByToken(config.cancelToken); }
			return result;
		}).catch((e) => {
			if (config.cancelToken) { this.ajaxRequestManager.removeByToken(config.cancelToken); }
			throw e;
		});
	}

	_applyUrlParams(config /* : Object */) {
		// Replace :param parameters in the URL
		config.url = config.url.replace(/:([a-zA-Z0-9_]+)/g, function (colonParamName, paramName) {

			const params = config.params || {};

			// Get the value for this parameter
			const paramValue = params[paramName];

			// Remove the param from the params object so it doesn't get sent in the URL
			delete params[paramName];

			return !_.isUndefined(paramValue) ? paramValue : `:${paramName}`;
		});
	}

	setCancelId(id, opts = {}) {

		_.defaults(opts, {
			onCancel: () => {},
		});

		id = id || this.id;
		return this.ajaxRequestManager.add(id, opts);
	}

	isCancel(thrown) {
		return Http.isCancel(thrown);
	}

	get ajaxRequestManager() {
		return Http.ajaxRequestManager;
	}

	static cancelPendingRequests(fn) {
		this.ajaxRequestManager.cancelPendingRequests(fn);
	}

	static getCancelObject() {
		return new axios.Cancel();
	}

	// Returns a singleton AjaxRequestManager for all HTTP requests.
	static get ajaxRequestManager() {
		if (!this._ajaxRequestManager) {
			this._ajaxRequestManager = new AjaxRequestManager();
		}
		return this._ajaxRequestManager;
	}

	static isCancel(thrown) {
		return axios.isCancel(thrown);
	}
}
