export default function getObjectByValue(array, propertyName, selValue) {
	let result = null;
	if (array && array.length > 0) {
		for (let index = 0; index < array.length; index++) {
			const checkObject = array[index];
			if (checkObject[propertyName] === selValue) {
				result = checkObject;
				break;
			}
		}
	}
	return result;
}
