const Cypress = typeof window !== 'undefined' ? window.Cypress : undefined;

function registerCypressObject(name, objectToRegister) {
    if (typeof Cypress !== 'undefined') {
        Cypress.adobeRefs = Cypress.adobeRefs || {};
        Cypress.adobeRefs[name] = objectToRegister;
    }
}

export default registerCypressObject;
