export default function handleDelegatedEvent(selector, handler, e) {
    if (!selector) {
        handler.call(e.target, e);
        return;
    }
    for (let target = e.target; target && target !== e.currentTarget; target = target.parentNode) {
        // loop parent nodes from the target to the delegation node
        if (target.matches(selector)) {
            e.delegatedTarget = target;
            handler.call(target, e);
            break;
        }
    }
}
