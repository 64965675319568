/* @flow */
import _isPlainObject from './util/_isPlainObject';

export default class Validator {

	isValid(value) {
		return false;
	}

	ensureValid(field, value) {
		if (!this.isValid(value)) {
			throw new Error('Invalid value for \'' + field + '\'. Value was \'' + value + '\'.');
		}
	}

	static create(type) {
		let typeInfo;

		if (_isPlainObject(type)) {
			typeInfo = type;
		} else {
			typeInfo = {type: type};
		}

		type = typeInfo.type;

		if (typeof (type) === 'string') {
			const typeName = type.split(' ')[0];
			switch (typeName) {
				case 'string':
					return new StringValidator();
				case 'number':
					return new NumberValidator();
				case 'boolean':
					return new BooleanValidator();
				case 'enum':
					return new EnumValidator(typeInfo);
				default:
					throw new Error('Unknown validator type: ' + type);
			}
		} else {
			switch (type) {
				case Number:
					return new NumberValidator();
				case String:
					return new StringValidator();
				case Boolean:
					return new BooleanValidator();
				default:
					return new ClassValidator(type);
			}
		}
	}

}

class StringValidator extends Validator {

	isValid(value) {
		return isType(value, 'string');
	}

}

class EnumValidator extends StringValidator {

	constructor(typeInfo) {
		super();
		this.enumValues = typeInfo.values;
	}

	isValid(value) {
		return super.isValid(value) && this.enumValues.indexOf(value) > -1;
	}

}


class NumberValidator extends Validator {

	isValid(value) {
		return isType(value, 'number');
	}

}


class BooleanValidator extends Validator {

	isValid(value) {
		return isType(value, 'boolean');
	}

}

class ClassValidator extends Validator {

	constructor(type) {
		super();
		if (!type) { throw new Error('You must define a type.'); }
		this.validType = type;
	}

	isValidType(value) {
		if (Array.isArray(this.validType)) {
			return this.validType.some(function (validType) {
				return value instanceof validType;
			});
		} else {
			// Object is kind of an 'any', e.g. we let anything validate
			return this.validType === Object || value instanceof this.validType;
		}
	}

	isValid(value) {
		return isUnset(value) || this.isValidType(value);
	}

}

/** Validator Utility Functions **/

function isUnset(value) {
	return (value === null) || (value === undefined);
}

function isType(value, type) {
	return isUnset(value) || typeof (value) === type;
}
