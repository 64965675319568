export default class RepositoryPlugin {

	constructor(options) {
		this._options = options;
		this._cache = options.cache;
		this._http = options.http;
	}

	preSave(entity, options, isNew) {
		return Promise.resolve();
	}

	postSave(entity, options, isNew) {
		return Promise.resolve();
	}

	postGet(entity, options) {
		return Promise.resolve();
	}

}
