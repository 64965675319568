/* eslint global-require: 0 */
import EventEmitter from './EventEmitter';
import _clone from './util/_clone';

/*
	type Config {
		parent: any, // if defined anything added to this list will have it's parent set to this value
	};
 */

export default function ObservableList(listItems = [], config = {}) {

	if (!Array.isArray(listItems)) {
		throw new Error('items should be an array, but is not.');
	}

	let list = listItems.concat();

	// Observe items that we start with, if observeitems is true
	if (config.observeItems) {
		list.forEach(observe);
	}
	if (config.parent) {
		setParent(list);
	}
	list._handlers = {};
	list._supressedEvents = [];
	list._batchEvents = false;
	list._batchedHandlers = [];
	list.on = EventEmitter.prototype.on.bind(list);
	list.off = EventEmitter.prototype.off.bind(list);
	list.emit = EventEmitter.prototype.emit.bind(list);
	list.supressEvent = EventEmitter.prototype.supressEvent.bind(list);
	list.startBatchingEvents = EventEmitter.prototype.startBatchingEvents.bind(list);
	list.runBatchedHandlers = EventEmitter.prototype.runBatchedHandlers.bind(list);
	list.isObservableList = true;


	// Overwrite builtin methods so we can emit change events //

	list.push = function (...args) {
		return addItems(args, list.length);
	};

	list.unshift = function (...args) {
		return addItems(args, 0);
	};

	list.shift = function (...args) {
		let result = Array.prototype.shift.apply(list, args);
		handleChange({
			removed: [result],
			removedIndex: result ? 0 : undefined,
		});
		return result;
	};

	list.pop = function (...args) {
		let result = Array.prototype.pop.apply(list, args);
		handleChange({
			removed: result,
			removedIndex: result ? list.length : undefined,
		});
		return result;
	};

	list.splice = function (...args) {
		let [index, removeCount] = args;
		let itemsToAdd = args.slice(2);
		// Remove the items that need removing
		let result = Array.prototype.splice.apply(list, args.slice(0, 2));
		// Add the items and fire the change event
		addItems(itemsToAdd, index, {
			removed: result,
			removedIndex: result ? index : undefined,
		});
		return result;
	};

	list.sort = function (...args) {
		let oldSortOrder = _clone(list);
		let result = Array.prototype.sort.apply(list, args);
		let newSortOrder = _clone(list);
		handleChange({newSortOrder, oldSortOrder});
		return result;
	};

	list.reverse = function (...args) {
		let oldSortOrder = _clone(list);
		let result = Array.prototype.reverse.apply(list, args);
		let newSortOrder = _clone(list);
		handleChange({newSortOrder, oldSortOrder});
		return result;
	};

	// Add some nice convenience methods //

	list.first = function () {
		return list[0];
	};

	list.last = function () {
		return list[list.length - 1];
	};

	list.pushAll = function (items) {
		list.push(...items);
	};

	list.insert = function (item, index) {
		list.insertAll([item], index);
	};

	list.insertAll = function (items, index) {
		if (index === undefined) {
			throw new Error('index is required when calling array.insert');
		}
		list.splice(...[index, 0].concat(items));
	};

	list.replace = function (item1, item2) {
		let index = list.indexOf(item1);
		list.replaceAt(index, item2);
	};

	list.replaceAt = function (index, item) {
		list.splice(index, 1, item);
	};

	list.replaceAll = function (items) {
		list.splice(0, list.length, ...items);
	};

	list.empty = function () {
		list.splice(0, list.length);
	};

	list.remove = function (item) {
		let index = list.indexOf(item);
		if (index > -1) {
			list.removeAt(index);
		}
	};

	list.removeAt = function (index) {
		list.splice(index, 1);
	};

	list.findAndRemove = function (fn) {
		const item = list.find(fn);
		list.remove(item);
	};

	list.contains = function (item) {
		return list.indexOf(item) > -1;
	};

	list.containsAny = function (items) {
		return items.some(list.contains);
	};

	list.beforeAdd = getBeforeAdd();

	list.copy = function () {
		return new ObservableList(list.slice());
	};

	function addItems(items, index, changeArgs) {
		changeArgs = changeArgs || {};

		if (config.parent) {
			setParent(items);
		}

		// Run the items through the beforeAdd filter
		let add = {
			items: items || [],
			index: index,
		};
		list.beforeAdd(add);

		Array.prototype.splice.apply(list, [add.index, 0].concat(add.items));

		changeArgs.added = add.items;
		changeArgs.addedIndex = add.items.length ? add.index : undefined;
		handleChange(changeArgs);

		return list.length;
	}

	function setParent(items) {
		items.forEach((item) => {
			if (item instanceof ObservableList.Model && config.setParent !== false) {
				item.parent = config.parent;
			}
		});
	}

	function preventDuplicates(add) {
		add.items = add.items.filter(function (item) {
			// Only allow this item if it isn't already in the list
			return !list.some(function (compareItem) {
				if (config.key) {
					// If a key is provided, use that as the id to use for comparison
					return item[config.key] === compareItem[config.key];
				} else {
					return item === compareItem;
				}
			});
		});
	}

	function moveDuplicates(add) {
			add.items.forEach(function (item) {
				if (list.contains(item)) {
					let index = list.indexOf(item);
					list.removeAt(index);
					// if the item we removed was above the add.index, we need to
					// adjust the add index
					if (index < add.index) {
						add.index -= 1;
					}
				}
			});
	}

	function getBeforeAdd() {
		if (config.beforeAdd) {
			return config.beforeAdd;
		} else if (config.duplicates === 'prevent') {
			return preventDuplicates;
		} else if (config.duplicates === 'move') {
			return moveDuplicates;
		} else {
			return function () {};
		}
	}

	function handleChange(args) {
		if (config.observeItems) {
			if (args.added) {
				args.added.forEach(observe);
			}
			if (args.removed) {
				args.removed.forEach(unobserve);
			}
		}
		emitListChange(args);
	}

	function emitListChange(args) {
		list.emit('change', Object.assign(args || {}, { type: 'list' }));
	}

	function itemChange(e) {
		emitListChange({parentEvent: e, item: this, observed: true});
	}

	function observe(item) {
		if (!item) { return; }
		item.on('change', itemChange);
	}

	function unobserve(item) {
		if (!item) { return; }
		item.off('change', itemChange);
	}

	return list;
}
