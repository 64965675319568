import { EventEmitter } from '@analytics/essence';
import _ from './_';

export default class EntityCollection extends EventEmitter {

	constructor(query) {
		super();
		this._query = query;
		this._lastPromise = undefined;
		this.items = undefined;
	}

	load(cache) {
		if (!this._lastPromise) {
			this._lastPromise = this._query(cache).then((items) => {
				// Add or update items in the cache
				if (cache) {
					items.forEach(item => cache.add(item));

					const itemsFromCache = items.map(item => cache.get(item.constructor, item.id));
					this.items = itemsFromCache;
					return items;
				}
				this.items = items;
				return items;
			});
		}
		return this._lastPromise;
	}

	refresh(cache) {
		this._lastPromise = undefined;
		this.emit('refresh', {cache});
		return this.load(cache).then((result) => {
			this.notifyChange();
			return result;
		});
	}

	notifyChange() {
		this.emit('change');
	}

	add(item) {
		// Add the item if it isn't already in the list
		// If someone hasn't requested the collection yet then items will be undefined.
		const itemIndex = _.findIndex(this.items, {id: item.id});
		if (this.items && itemIndex === -1) {
			this.items.push(item);
			this.notifyChange();
		} else if (this.items && itemIndex > -1) {
			const listItem = this.items[itemIndex];
			listItem.update(item);
			this.notifyChange();
		}
	}

	remove(item) {
		const itemIndex = _.findIndex(this.items, {id: item.id});
		if (itemIndex !== -1) {
			this.items.splice(itemIndex, 1);
			this.notifyChange();
		}
	}

	/*
	* Creates a collection that is ready when another collection is ready
	* and contains a filtered view of another collection
	*/
	static filteredCollection(collection, filter) {

		/* When query is called, resolve the original collection's load and filter the items */
		const filteredCollection = new EntityCollection(function () {
			return collection.load().then(items => items.filter(filter));
		});

		/* When the original collection is refreshed, refresh this collection */
		collection.on('refresh', function ({cache} = {}) {
			filteredCollection.refresh(cache);
		});

		return filteredCollection;
	}

	/*
	* Creates a collection that is ready another set of collections is ready
	*/
	static combinedCollection(collections, definition) {

		/* When query is called, resolve the original collection's load */
		const combinedCollection = new EntityCollection(function (cache) {

			const allPromises = collections.map(collection => collection.load(cache));

			return Promise.all(allPromises)
					.then((results) => {
						let allItems = [];
						results.forEach((result) => { allItems = allItems.concat(result); });
						if (definition.sort) {
							allItems.sort(definition.sort);
						}
						return allItems;
					});
		});

		const debouncedRefresh = _.debounce(function ({cache} = {}) {
			combinedCollection.refresh(cache);
		}, 10);

		/* When one of the underyisng collections change, refresh this collection */
		collections.forEach(collection => collection.on('refresh', debouncedRefresh));

		return combinedCollection;
	}

}
