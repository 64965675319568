export default function loadScript(url) {
	let window = typeof window === 'undefined' ? global : window;
	return new Promise(function (resolve, reject) {
		let resolved = false;
		const headerTag = window.document.getElementsByTagName("head")[0];
		const scriptTag = window.document.createElement("script");

		scriptTag.type = "text/javascript";
		scriptTag.src = url;
		scriptTag.async = true;
		scriptTag.onload = function () {
			if (!resolved && (!this.readyState || this.readyState === "complete")) {
				resolved = true;
				resolve(this);
			}
		};
		scriptTag.onreadystatechange = scriptTag.onload;

		scriptTag.onerror = reject;
		scriptTag.onabort = reject;
		headerTag.appendChild(scriptTag);
	});
}
