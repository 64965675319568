import { Model } from '@analytics/essence';
import Entity from './Entity';
import _ from './_';

const ENTITY_NAME = 'Entity';

export default class SchemaGenerator {

	/**
		Generate a json schema from a given model definition.
	**/
	generateSchema(modelType, rootSchema) {

		if (!Object.prototype.isPrototypeOf.call(Model, modelType)) {
			throw new Error('You must provide a model type.');
		}

		const schema = {
			$schema: 'http://json-schema.org/draft-04/schema#',
			title: modelType.name + ' Schema',
			definitions: {},
			type: 'object',
			properties: {},
			additionalProperties: false,
		};

		const properties = modelType.modelDefinition.properties;

		properties.forEach((property) => {

			if (property.transient) { return; }

			try {
				schema.properties[property.name] = this._schemaProperty(rootSchema || schema, property);
			} catch (e) {
				throw new Error(modelType.name + '.' + property.name + ': ' + e.toString());
			}

		});

		return schema;
	}

	/**
		Generate a schema property for the model property
	**/
	_schemaProperty(schema, property) {

		let schemaProperty;

		if (isArrayProperty(property)) {
			schemaProperty = this._arrayProperty(schema, property);
		} else if (property.type.isModel) {
			schemaProperty = this._reference(schema, property.type);
		} else if (property.isMultipleModelProperty) {
			schemaProperty = this._multipleModelProperty(schema, property);
		} else {
			schemaProperty = this._schemaPropertyNonModelType(property);
		}

		// Pass through any schema annotations
		return _.extend({}, property.schema, schemaProperty);
	}

	/**
		Generate a schema property for non-model property types (e.g. String, Number, enum, etc...)
	**/
	_schemaPropertyNonModelType(property) {
		// Create the schema property definition
		const schemaProperty = {
			type: this._schemaType(property.type),
		};

		if (property.type === Date) {
			schemaProperty.format = 'date-time';
		}

		// If it is an array, add the array type
		if (schemaProperty.type === 'array') {
			schemaProperty.items = {
				type: this._schemaType(property.itemType),
			};
		}

		// If this is an enum, add the enum values
		if (property.type === 'enum') {
			schemaProperty.enum = property.values;
		}

		return schemaProperty;
	}


	/**
		Generate a schema type from basic model property types
	**/
	_schemaType(type) {
		switch (type) {
			case Object:
				return 'object';
			case String:
			case 'string':
			case 'enum':
				return ['string', 'null'];
			case Number:
			case 'number':
				return ['number', 'null'];
			case Boolean:
			case 'boolean':
				return ['boolean', 'null'];
			case Array:
			case 'array':
				return 'array';
			case Date:
				return 'string';
			default:
				throw new Error('Unknown type: ' + type);
		}
	}

	/**
		Adds the provided model to the schema's definitions section, if it doesn't already exist
	**/
	_ensureDefinition(schema, model) {
		if (schema.definitions[model.name]) { return; }

		// Add a placeholder, so if we end up in a cycle, it finds the name already there
		schema.definitions[model.name] = {};

		// Generate the sub-schema definition
		const definition = _.omit(this.generateSchema(model, schema), ['$schema', 'title', 'definitions']);

		// Set the sub-schema as teh actual definition
		schema.definitions[model.name] = definition;
	}

	_ensureEntityDefinition(schema) {
		if (schema.definitions[ENTITY_NAME]) { return; }
		schema.definitions[ENTITY_NAME] = this._entity();
	}

	/**
		Creates a property that references sub-schema e.g. #/definitions/sub-schema
	**/
	_reference(schema, model) {
		let refName = model.name;
		if (Object.prototype.isPrototypeOf.call(Entity, model)) {
			// Entities all share the same ref
			this._ensureEntityDefinition(schema);
			refName = ENTITY_NAME;
		} else {
			this._ensureDefinition(schema, model);
		}
		return {
			$ref: '#/definitions/' + refName,
		};
	}

	/**
		Creates a property for an Entity
	**/
	_entity() {
		return {
			type: "object",
			properties: {
				__entity__: {
					type: "boolean",
					enum: [true],
				},
				id: {
					type: "string",
				},
				type: {
					type: "string",
				},
				__metaData__: {
					type: "object",
				},
			},
			additionalProperties: false,
		};
	}


	/**
		Generate a schema property for an array property
	**/
	_arrayProperty(schema, property) {
		return {
			type: 'array',
			items: this._arrayItemsSchema(schema, property),
		};
	}

	/**
		Generate a schema property for a multiple model property
	**/
	_multipleModelProperty(schema, property) {
		const refs = _.uniq(property.type.map(type => this._reference(schema, type)), '$ref');
		return {
			anyOf: refs,
		};
	}

	/**
		Generate the schema for the item type (items in JSON Schema lingo/itemType in model lingo)
	**/
	_arrayItemsSchema(schema, property) {

		if (_.isArray(property.itemType)) {

			const refs = _.uniq(property.itemType.map(itemType => this._reference(schema, itemType)), '$ref');

			return {anyOf: refs};
		} else {
			return this._reference(schema, property.itemType);
		}

	}
}

function isArrayProperty(property) {
	if (property.type === Array && !property.itemType) {
		throw new Error('ItemType cannot be null or undefiend for an Array property');
	}
	return property.type === Array && (_.isArray(property.itemType) || property.itemType.isModel);
}
