/* @flow */
import { Model } from '@analytics/essence';
import _ from './_';
import EntityRepository from './EntityRepository';

/*::
import Migration from './Migration';
*/

export default class Entity extends Model {

	/*::
		id: string;
		static _migrations: Array<Migration>;
	*/

	static properties() /*: Object */ {
		return {
			id: { type: String, undoable: false },
		};
	}

	static fields() {
		return [
			'__cached__', // Whether or not this item has been processed by the cache, internal
		];
	}

	static endpoint() {
		throw Error(`${this.name} should define static method 'endpoint', but does not.`);
	}

	static repository() {
		return EntityRepository;
	}

	static repositoryPlugins() {
		return [];
	}

	static entityType() {
		throw new Error('You must define an entity type by overriding static entityType().');
	}

	static fromJSON(json) {
		return super.fromJSON(this.migrate(json));
	}

	set parent(value) {
		// ignore when someone sets a parent on an entity
	}

	get parent() /*: ?Model */ {
		return undefined;
	}

	static addMigration(migration) {
		this._migrations = this._migrations || [];
		this._migrations.push(migration);
	}

	static migrate(json) {
		if (this._migrations) {
			const migration = this._migrations.find(migration1 => migration1.canMigrate(json));
			if (migration) {
				// Apply the migration
				json = migration.migrate(json);
				// Run migration again, on the newly migrated json, so we can chain migrations
				json = this.migrate(json);
			}
		}
		return json;
	}

	getEmbeddedEntities(options) /*: Array<Entity> */ {
		return this.getEmbeddedModels(Entity, options);
	}

	update(source /* : any */, { propertiesToUpdate = [] } = {}) {

		if (this === source) {
			return;
		}

		const copyModel = (model) => {
			if (model instanceof Entity) {
				return model;
			} else {
				return model.copy();
			}
		};

		this._modelDefinition.properties.forEach((property) => {
			// Only update the whitelisted set of properties if one was passed in.
			if (propertiesToUpdate.length &&
				!propertiesToUpdate.includes(property.name) &&
				/* Expansions should always be updated especially in the case of reload */
				property.name !== '_expansions') {
				return;
			}
			// $FlowIgnore
			// AN-142287 - When assigning model properties, make a copy so the originals don't get reparented
			if (!property.readOnly) {
				if (property.isModelProperty && source[property.name]) {
					this[property.name] = copyModel(source[property.name]);
				} else if (property.isModelList) {
					this[property.name] = source[property.name].map(el => copyModel(el));
				} else {
					this[property.name] = source[property.name];
				}
			}
		});
	}

	toJSON(options /*: Object */ = {}) {
		let json = super.toJSON(options);
		if (options.embedded) {
			json = this._getEmbeddedJSON(json);
		}
		return json;
	}

	_getItemType(property /*: Model.Property */, itemJSON /*: Object */) /*: function */ {
		if (Object.prototype.isPrototypeOf.call(Entity, property.type)) {
			return property.type;
		} else if (this._isArrayEntity(property)) {
			return this._getEntityType(property, itemJSON);
		} else {
			return super._getItemType(property, itemJSON);
		}
	}

	_isArrayEntity(property /*: Model.Property */) {
		const itemTypes = property.itemTypes || [];
		return itemTypes.some(item => Object.prototype.isPrototypeOf.call(Entity, item));
	}

	_getEntityType(property, itemJSON) {
		if (property.itemTypes.length === 1) {
			return property.itemTypes[0];
		}
		const entityType = property.itemTypes.find(itemType => itemType.name === itemJSON.type);
		if (!entityType) {
			throw new Error(`No itemType for property ${property.name} matches type '${itemJSON.type}'`);
		}
		return entityType;
	}

	_getEmbeddedJSON(json) {
		const embeddedJSON = {id: json.id, __entity__: true};
		embeddedJSON.type = this.constructor.name;
		return embeddedJSON;
	}

	_parseJSON(json, options) {
		if (json.__entity__) {
			json = _.omit(json, 'type');
		}
		return super._parseJSON(json, options);
	}

	static needsHydration(model) {
		return true;
	}

	static canParse(json) {
		return json.__entity__ === true && json.type === this.name;
	}
}
