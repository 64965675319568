/*eslint no-bitwise:0*/
import _ from './../_';

function objectToHashCode(str) {
	str = _.isObject(str) ? JSON.stringify(str) : str; // Allow for objects to be passed in.
	let hash = 0;
	let i;
	let chr;
	let len;
	if (str.length === 0) {
		return hash;
	}
	for (i = 0, len = str.length; i < len; i++) {
		chr   = str.charCodeAt(i);
		hash  = ((hash << 5) - hash) + chr;
		hash |= 0; // Convert to 32bit integer
	}
	return hash;
}

export default objectToHashCode;
