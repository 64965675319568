/*eslint no-console:0*/
import _ from './_';

const htmlAttrs = {
	about: true,
	datatype: true,
	defaultValue: true,
	defaultChecked: true,
	inlist: true,
	prefix: true,
	property: true,
	resource: true,
	typeof: true,
	vocab: true,
	autoCapitalize: true,
	autoCorrect: true,
	color: true,
	itemProp: true,
	itemScope: true,
	itemType: true,
	itemRef: true,
	itemID: true,
	security: true,
	unselectable: true,
	results: true,
	autoSave: true,
	onBlur: true,
	onChange: true,
	onClick: true,
	onContextMenu: true,
	onCopy: true,
	onCut: true,
	onDoubleClick: true,
	onDrag: true,
	onDragEnd: true,
	onDragEnter: true,
	onDragExit: true,
	onDragLeave: true,
	onDragOver: true,
	onDragStart: true,
	onDrop: true,
	onFocus: true,
	onInput: true,
	onKeyDown: true,
	onKeyPress: true,
	onKeyUp: true,
	onMouseDown: true,
	onMouseEnter: true,
	onMouseLeave: true,
	onMouseMove: true,
	onMouseOut: true,
	onMouseOver: true,
	onMouseUp: true,
	onPaste: true,
	onScroll: true,
	onSubmit: true,
	onTouchCancel: true,
	onTouchEnd: true,
	onTouchMove: true,
	onTouchStart: true,
	onWheel: true,
	abbr: true,
	accept: true,
	acceptCharset: true,
	accessKey: true,
	action: true,
	allowFullScreen: true,
	allowTransparency: true,
	alt: true,
	async: true,
	autoComplete: true,
	autoFocus: true,
	autoPlay: true,
	cellPadding: true,
	cellSpacing: true,
	challenge: true,
	charset: true,
	checked: true,
	cite: true,
	class: true,
	className: true,
	cols: true,
	colSpan: true,
	command: true,
	content: true,
	contentEditable: true,
	contextMenu: true,
	controls: true,
	coords: true,
	crossOrigin: true,
	data: true,
	dateTime: true,
	default: true,
	defer: true,
	dir: true,
	disabled: true,
	download: true,
	draggable: true,
	droptarget: true,
	encType: true,
	for: true,
	form: true,
	formAction: true,
	formEncType: true,
	formMethod: true,
	formNoValidate: true,
	formTarget: true,
	frameBorder: true,
	headers: true,
	height: true,
	hidden: true,
	high: true,
	href: true,
	hrefLang: true,
	htmlFor: true,
	httpEquiv: true,
	icon: true,
	id: true,
	inputMode: true,
	isMap: true,
	itemId: true,
	kind: true,
	label: true,
	lang: true,
	list: true,
	loop: true,
	manifest: true,
	max: true,
	maxLength: true,
	media: true,
	mediaGroup: true,
	method: true,
	min: true,
	minLength: true,
	multiple: true,
	muted: true,
	name: true,
	noValidate: true,
	open: true,
	optimum: true,
	pattern: true,
	ping: true,
	placeholder: true,
	poster: true,
	preload: true,
	radioGroup: true,
	readOnly: true,
	rel: true,
	required: true,
	role: true,
	rows: true,
	rowSpan: true,
	sandbox: true,
	scope: true,
	scoped: true,
	scrolling: true,
	seamless: true,
	selected: true,
	shape: true,
	size: true,
	sizes: true,
	sortable: true,
	span: true,
	spellCheck: true,
	src: true,
	srcDoc: true,
	srcSet: true,
	start: true,
	step: true,
	style: true,
	tabIndex: true,
	target: true,
	title: true,
	translate: true,
	type: true,
	typeMustMatch: true,
	useMap: true,
	value: true,
	width: true,
	wmode: true,
	wrap: true,
};

export default function pickProps(props) {

	if (props === null) {
		return {};
	}

	if (!_.isPlainObject(props)) {
		console.error('props should be a plain object, you passed in', props);
		//throw new Error('props should be a plain object');
	}
	const validProps = {};
	_.each(props, (propValue, propName) => {
		if (/^(data|aria)-/.test(propName) || htmlAttrs[propName]) {
			validProps[propName] = propValue;
		}
	});

	return validProps;
}
