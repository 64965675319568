import getQueryParams from './getQueryParams';
import getHashFromUrl from './getHashFromUrl';

export default function getHashParams(url) {
	url = url || window.location.href;
	let hash = getHashFromUrl(url);
	if (hash.indexOf('?') !== -1 && hash.substring(hash.indexOf('?') + 1)) {
		return getQueryParams(hash);
	} else {
		return {};
	}
}
