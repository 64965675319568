import handleDelegatedEvent from './handleDelegatedEvent';

export default function delegateEvent(element, events /* space separated list of event strings */, selector, handler) {
    const onEvent = handleDelegatedEvent.bind(null, selector, handler);

    const offHandlers = [];
    events.replace(/\s\s+/g, ' ').split(' ').forEach(event => {
        element.addEventListener(event, onEvent);
        offHandlers.push(() => element.removeEventListener(event, onEvent));
    });

    const off = () => offHandlers.forEach(h => h());
    return off;
}
