import hexRgb from './../hexRgb';

const DEFAULT_COLOR = '#cccccc';

let defaultColorScheme = {
	label: '',
	id: 'default',
	value: [
		'#73B43A',
		'#3B4197',
		'#BB1C74',
		'#40C4C2',
		'#F19323',
		'#9C64E0',
		'#20A2A7',
		'#1473E5',
		'#EB5B62',
		'#8DDD49',
		'#CA6F10',
		'#4CAAF4',
		'#379847',
		'#B030BC',
		'#D6373F',
		'#ECCB00'
	]
};

let customColors;

const colorPalette = {

	defaultColorSchemeOptions: [
		defaultColorScheme,
		{
			label: '',
			id: 'default_A',
			value: ['#ED6332', '#4854BD', '#2EB4B7', '#D35DA6', '#A8B53A', '#DC4248', '#595099', '#D99D27', '#4F8BCF', '#3D945B', '#A865A7', '#E15171', '#8F3D75', '#6DB443', '#0D7B87', '#DD7F22']
		},
		{
			label: '',
			id: 'default_B',
			value: ['#2B8F7F', '#2C598A', '#7C79B3', '#AA498F', '#DC6E5A', '#C33D45', '#48B3A9', '#0C6670', '#638A49', '#F58542', '#E15A78', '#AB2764', '#683D7D', '#4A85C9', '#8DA84D', '#CF5C32']
		},
		{
			label: '',
			id: 'default_C',
			value: ['#F57325', '#DC2653', '#AE3391', '#640A6E', '#4646B4', '#00BED2', '#00826E', '#BEC634', '#DC4600', '#DC4675', '#990C3E', '#493E99', '#3D7FCC', '#00A0AA', '#2A9636', '#054F43']
		},
		{
			label: '',
			id: 'default_D',
			value: ['#406D94', '#74B4AC', '#6D9870', '#BEBA60', '#E18A3F', '#B73C59', '#C3627B', '#6C4E7C', '#6A91B0', '#527864', '#94CD9E', '#FAB55D', '#D96452', '#E26A80', '#94386C', '#8D6F9C']
		},
	],

	getColors: () => [...(customColors || defaultColorScheme.value)],

	setColors: (value) => {
		customColors = value;

		//If color scheme is less than 16 values, loop through and repeat until it is 16 values
		const usedColors = [];
		_.range(0, 16).forEach((i) => {
			const color = colorPalette.getNextColor(usedColors);
			usedColors.push(color);
			let rgb = hexRgb(color);
			if (typeof document !== 'undefined' && typeof document.documentElement !== 'undefined') {
				document.documentElement.style.setProperty(`--an-color-${i + 1}`, color);
				document.documentElement.style.setProperty(`--an-color-${i + 1}r`, rgb.red);
				document.documentElement.style.setProperty(`--an-color-${i + 1}g`, rgb.green);
				document.documentElement.style.setProperty(`--an-color-${i + 1}b`, rgb.blue);
			}
		});
		customColors = usedColors;
	},

	// Find the next color based on a list of colors that have already been used
	// For example, if COLORS was ['red', 'green', 'blue']:
	// If usedColors is ['red']                           then this would return 'green'
	// If usedColors is ['red', 'blue']                   then this would return 'green'
	// If usedColors is ['red', 'blue', 'green', 'green'] then this would return 'red'
	// If usedColors is ['red', 'green', 'red', 'green']  then this would return 'blue'
	getNextColor: (usedColors = [], colorScheme = (customColors || defaultColorScheme.value)) => {
		let nextColor;
		usedColors = [...usedColors];
		while (!nextColor) {
			const colorPaletteColors = [...colorScheme];
			for (let i = 0; i < colorPaletteColors.length; i++) {
				const colorPaletteColor = colorPaletteColors[i];
				const usedColorIndex = usedColors.indexOf(colorPaletteColor);
				if (usedColorIndex < 0) {
					nextColor = colorPaletteColor;
					break;
				}
				usedColors.splice(usedColorIndex, 1);
			}
		}
		return nextColor;
	},
};

colorPalette.DEFAULT_COLOR = DEFAULT_COLOR;

export default colorPalette;
