/* @flow */
import { EventEmitter } from '@analytics/essence';
import Entity from './Entity';

export default class EntityProvider extends EventEmitter {

	get/*::<T:Entity>*/(type /*: Class<T> */, id /*: string */)  /*: T */ {
		throw new Error("not implemented");
	}

}
