import _ from './_';


export default {

   generateSnapIntervals: ({containerWidth = 100, intervalCount = 12, startWithZero = false, flooringPrecision = 2} = {}) => {

      let interval = containerWidth / intervalCount;
      return _.times(intervalCount, n => {
         let adjustedN = startWithZero ? n : n + 1;
         return _.floor(interval * adjustedN, flooringPrecision);
      });
   },

   findClosest: (array, val) => {
     return _.min(array, num => Math.abs(val - num));
   }

};
