/*eslint no-bitwise:0,no-nested-ternary:0,no-mixed-operators:0*/
export default {
    create: function ({ delimiter = '-', lowerCase = false } = {}) {
        function calculate(src) {
            return hexSha1(src);
        }

        function hexSha1(src) {
            return binb2hex(coreSha1(str2binb(src), src.length * 8));
        }

        function coreSha1(x, len) {
            x[len >> 5] |= 0x80 << (24 - len % 32);
            x[((len + 64 >> 9) << 4) + 15] = len;
            let w = new Array(80);
            let a = 1732584193;
            let b = -271733879;
            let c = -1732584194;
            let d = 271733878;
            let e = -1009589776;
            for (let i = 0; i < x.length; i += 16) {
                let olda = a;
                let oldb = b;
                let oldc = c;
                let oldd = d;
                let olde = e;
                for (let j = 0; j < 80; j++) {
                    if (j < 16) {
                        w[j] = x[i + j];
                    } else {
                        w[j] = rol(w[j - 3] ^ w[j - 8] ^ w[j - 14] ^ w[j - 16], 1);
                    }
                    const t = safeAdd(safeAdd(rol(a, 5), sha1Ft(j, b, c, d)), safeAdd(safeAdd(e, w[j]), sha1Kt(j)));
                    e = d; d = c;
                    c = rol(b, 30);
                    b = a; a = t;
                }
                a = safeAdd(a, olda);
                b = safeAdd(b, oldb);
                c = safeAdd(c, oldc);
                d = safeAdd(d, oldd);
                e = safeAdd(e, olde);
            }
            return [a, b, c, d, e];
        }

        function sha1Ft(t, b, c, d) {
            if (t < 20) { return (b & c) | ((~b) & d); }
            if (t < 40) { return b ^ c ^ d; }
            if (t < 60) { return (b & c) | (b & d) | (c & d); }
            return b ^ c ^ d;
        }

        function sha1Kt(t) {
            return (t < 20) ? 1518500249 : (t < 40) ? 1859775393 : (t < 60) ? -1894007588 : -899497514;
        }

        function safeAdd(x, y) {
            const lsw = (x & 0xFFFF) + (y & 0xFFFF);
            const msw = (x >> 16) + (y >> 16) + (lsw >> 16);
            return (msw << 16) | (lsw & 0xFFFF);
        }

        function rol(num, cnt) {
            return (num << cnt) | (num >>> (32 - cnt));
        }

        function str2binb(str) {
            const bin = [];
            const mask = (1 << 8) - 1;
            for (let i = 0; i < str.length * 8; i += 8) {
                bin[i >> 5] |= (str.charCodeAt(i / 8) & mask) << (24 - i % 32);
            }
            return bin;
        }

        function binb2hex(binarray) {
            let str = '';
            const tab = '0123456789abcdef';
            for (let i = 0; i < binarray.length * 4; i++) {
                str += tab.charAt((binarray[i >> 2] >> ((3 - i % 4) * 8 + 4)) & 0xF) + tab.charAt((binarray[i >> 2] >> ((3 - i % 4) * 8)) & 0xF);
            }
            return str;
        }

        let counter = 0;
        let dt = new Date();
        let id1 = dt.getTime();
        let id2 = Math.random() * Number.MAX_VALUE;
        let id3 = typeof (window) !== 'undefined' ? window.location.href : '';
        let rawID = calculate(id1 + id3 + id2 + counter++)[lowerCase ? 'toLowerCase' : 'toUpperCase']();
        let finalString = rawID.substring(0, 8) +
            delimiter + rawID.substring(8, 12) +
            delimiter + rawID.substring(12, 16) +
            delimiter + rawID.substring(16, 20) +
            delimiter + rawID.substring(20, 32);

        return finalString;
    },
};
