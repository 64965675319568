
/* Abstract base class for migrations */

export default class Migration {

	canMigrate(json) {
		return false;
	}

	migrate(json) {
		return json;
	}

}
