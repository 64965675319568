function getQueryParams(url = null) {
	if (typeof window === 'undefined') {
		return {};
	}

	url = url || window.location.href;
	if (url && url.indexOf('?') !== -1) {
		url = url.substring(url.indexOf('?') + 1);
	}

	if (url && url.indexOf('#') !== -1) {
		url = url.substring(0, url.indexOf('#'));
	}

	let queryParams = {};
	let queryString = url;
	let queries = queryString.split('&');
	let i;
	let temp;

	for (i = 0; i < queries.length; i++) {
		temp = queries[i].split('=');
		queryParams[temp[0]] = temp[1];
	}

	return queryParams;
}

export default getQueryParams;
