/* @flow */

import EventMatcher from './EventMatcher';

export default class EventEmitter {

	/* ::
		_handlers: Object;
		_supressedEvents: Array<string>;
	*/

	constructor() {
		this._handlers = {};
		this._supressedEvents = [];
		this._batchEvents = false;
		this._batchedHandlers = [];
	}

	on(event /*: string | EventMatcher */, callback /*: Function */) /*: Function */ {
		const handlers = this._handlers[event] || [];
		handlers.push(callback);
		this._handlers[event] = handlers;
		return function () { this.off(event, callback); }.bind(this);
	}

	once(event /*: string | EventMatcher */, callback /*: Function */) {
		const off = this.on(event, (...args) => {
			off();
			callback.apply(this, args);
		});
	}

	off(event /*: string | EventMatcher */, callback /*: Function */) {
		const handlers = this._handlers[event] || [];
		const index = handlers.indexOf(callback);
		if (index > -1) {
			handlers.splice(index, 1);
		}
	}

	supressEvent(event /*: string */, fn /*: Function */) {

		this._supressedEvents.push(event);

		try {
			fn();
		} finally {
			this._supressedEvents.pop();
		}

	}

	emit(...args) {
		let [event] = args;
		if (this._supressedEvents.indexOf(event) > -1) { return; }

		args = args.slice(1);
		const handlers = this._handlers[event] || [];
		const handlersCopy = handlers.slice(0); // If an handler removes itself, we don't want to mess up the array.

		handlersCopy.forEach((handler) => {
			if (handler) {
				if (this._batchEvents) {
					this._batchedHandlers.push(handler.bind(this, ...args));
				} else {
					handler.apply(this, args);
				}
			}
		});

	}

	startBatchingEvents() {
		this._batchEvents = true;
	}

	runBatchedHandlers() {
		this._batchEvents = false;
		this._batchedHandlers.forEach(handler => handler());
		this._batchedHandlers = [];
	}

}
