import parser from 'papaparse';
import _ from './_';


const csvService = {
	convertJSONtoCSV: function (data, config = {}) {
		return parser.unparse(data, config);
	},
	convertJSONtoTSV: function (data) {
		return parser.unparse(data, {delimiter: '\t'});
	},
	convertCSVtoJSON: function (data, delim) {
		delim = delim || ',';
		return parser.parse(data, {delimiter: delim});
	},
	// Have a single place to handle the header for the csv.
	// Headers is an array of strings. Each will go on its own line.
	getSimpleHeader: function (headers, opts) {
		opts = _.extend({
			firstCharacter:   '#', // Character to put at the start of each header line.
			dividerCharacter: '#', // Character to repeat for the divider lines.
			size:             'medium', // medium | large
		}, opts);

		const dividerSize = {
			medium: 45,
			large:  65,
		};

		const divider = [opts.firstCharacter + _.repeat(opts.dividerCharacter, dividerSize[opts.size])];
		const result = [divider];
		_.each(headers, function (header) {
			result.push([opts.firstCharacter + ' ' + header]);
		});
		result.push(divider);
		return result;
	},

	convertQuillToCSV(quillStr) {
		if (!quillStr) { return []; }
		const jsonObject = JSON.parse(quillStr);
		let content = '';
		jsonObject.ops.forEach((op) => {
			content += op.insert;
		});
		const initialArray = _.trim(content).split('\n');
		return initialArray.map(currentItem => [currentItem]);
	},
};


export default csvService;
