export default function getRootChangeEvent(event) {
   let e = event;
   while (e.parentEvent) {
      //if an array type, the added/removed (or sort) will be in the parent event
      if (e.parentEvent.added || e.parentEvent.removed || e.parentEvent.newSortOrder) {
         return e;
      }
      e = e.parentEvent;
   }
   return e;
}
