const unbackSlash = require('./unbackSlash');

/*
    finds all string literals passed into an l10n function
    returns a list of the following shape

    [{
        value: "translate me",
        customKey: "custom_key_193048"
    }, {
        value: "translate me as well",
        customKey: null
    }]
*/

const jsL10n = /l10n\(\s*(["'])(.*?[^\\])\1(\s*,\s*(["'])(.*?[^\\])\4)?/ig;

module.exports = function getl10nValuesFromFileString(fileString) {
    const results = [];
    let match;

    // eslint-disable-next-line
    while ((match = jsL10n.exec(fileString)) !== null) {
        results.push({
            value: unbackSlash(match[2]),
            customKey: match[5] || null
        });
    }
    return results;
};

