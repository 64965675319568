/* @flow */
import jsonp from 'jsonp';
import _ from './_';

function jsonpUrl(baseUrl/*: string */, params/*: Object */) /*: string */ {
	const paramsArray = [];
	_.forIn(params, function (value, key) {
		paramsArray.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
	});
	return `${baseUrl}?${paramsArray.join('&')}`;
}

export default (url/*: string */, params/*: ?Object */, opts/*: ?Object */) /*: Promise */ => new Promise((resolve, reject) => {
		if (params) {
			url = jsonpUrl(url, params);
		}
		jsonp(url, opts, (err, data) => {
			if (!err) {
				resolve(data);
			} else { // retry once after attempting to refresh the session.
				reject(err);
			}
		});
	});
