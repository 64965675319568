/* @flow */
import EventEmitter from './EventEmitter';
import Validator from './Validator';
import ObservableList from './ObservableList';
import guid from '@analytics/guid';
import _isPlainObject from './util/_isPlainObject';
import _clone from './util/_clone';
import _intersection from './util/_intersection';
import _flatten from './util/_flatten';
import _forEachRight from './util/_forEachRight';

/*
	Things to know when porting:
	- Use two .baelrc files - on with strict for analytics-ui and one for
	- rename fromJSON to _parseJSON
	- rename getItemType to _getItemType and signature changed (property, itemJSON) instaed of (propertyName, definition, itemJSON)
	- changed _properties to this._modelDefinition.properties
	- serializeDefaults ?? - in toJSON, etc...
	- cls.instanceof -> instanceof
	- setting list property is atomic - no more empty and then add
	- fields()
	- const
	- readOnly
	- replace list contents but not list
*/

/* ::
	type ModelConfig = {
		parseJSON?: boolean, // when true, data argument is parsed as JSON
		disableSeal?: boolean,
		copying?: boolean,
		parent?: Model
	};
*/

/* ::
	type ToJSONOptions = {
		transient?: boolean | string | Array<string>;
		serializeUndefined?: boolean;
	};
*/

/* ::
	type VisitOptions = {
		visited?: Set,
		context?: Object,
		excludeTags?: Array<string>,
		includeTags?: Array<string>,
		visitArrayInstances?: Boolean //if true, callback will be called with the array instance itself -
	};
*/

export default class Model extends EventEmitter {

	/*::
		_data: Object; // Where the actual data backing the properties is stored
		_observers: Object;
		$$hashKey: any;
			static _MODEL_DEFINITION_: ModelDefinition; // private backing field for static prperty modelDefinition
		parent: ?Model;
	*/

	constructor(data /*: Object */ = {}, config /*: ModelConfig */ = {}) {
		super();
		// Angular will add $$hashKey to objects in an ng-repeat, so we are adding it here, so when
		// we seal the model, it won't complain if Angular adds this property
		this.$$hashKey = undefined;

		this.constructor._init();
		this.__key = null;
		this._observers = {};
		this._data = {};
		this.parent = config.parent;
		this._addFields();
		this._seal(config);
		this.setData(data, config);
		this._initMixins();
	}

	static options() {
		return {};
	}

	get root() /*: Model */ {
		let current = this;
		while (current.parent) {
			current = current.parent;
		}
		return current;
	}

	get _key() /*: String */ {
		this.__key = this.__key || guid.create();
		return this.__key;
	}

	/*
		Visit each model in the tree.
	*/
	// Keep track of what we have already visited because we want to visit each model only once

	visit(callback /*: Function */, options /*: VisitOptions */ = {}) {
		options.visited = options.visited || new Set();
		if (options.visited.has(this)) { return; }
		options.visited.add(this);
		callback(this, options.context);

		this._modelDefinition.properties.forEach((property) => {
			if (options.excludeTags && options.includeTags) { throw new Error('You cannot call visit() with excludeTags AND includeTags.'); }
			if (options.excludeTags && _intersection(property.tags, options.excludeTags).length > 0) { return; }
			if (options.includeTags && _intersection(property.tags, options.includeTags).length === 0) { return; }
			property.visit(this, callback, options);
		});
	}

	/* Broadcast to this item and children */
	// Temporarily removing until parent observable is fixed.
	broadcast(event, ...args) {
		this._doBroadcast(true, event, args);
	}

	/* Broadcast to only children of this model */
	broadcastChildren(event, ...args) {
		this._doBroadcast(false, event, args);
	}

	/*
		Visit each property / array item and replace it with whatever is returned from the callback
	*/
	_replace(callback /*: Function */) {
		this._modelDefinition.properties.forEach((property) => {
			property._replace(this, callback);
		});
	}

	getEmbeddedModels(type /*: Model */ = Model, options /*: VisitOptions */ = {}) /*: Array<Object> */ {
		const embeddedModels = [];
		this.visit((model) => {
			if (model instanceof type && model !== this) {
				embeddedModels.push(model);
			}
		}, options);
		return embeddedModels;
	}

	getData() {
		return this._data;
	}

	setData(data /*: Object */, config /*: Object */ = {}) {
		if (config.parseJSON) {
			data = this._parseJSON(data);
		}

		// Ignore undefined or readOnly values.
		const initialData = data;
		data = {};
		Object.keys(initialData).forEach(key => {
			const property = this._modelDefinition.getProperty(key);
			const isReadOnlyProperty = property && property.readOnly;
			if (initialData[key] !== undefined && !isReadOnlyProperty) {
				data[key] = initialData[key];
			}
		});

		Object.assign(this, data);
	}

	emitPropertyChange(propertyName /*: string */, args /*: Object */) {
		const event = Object.assign({}, args, {
			type: 'model',
			property: propertyName,
			item: this,
		});
		this.emit('change', event);
		this.emit('change:' + propertyName, event);
	}

	observe(property /*: string */, obj /*: Model | ObservableList */) {
		if (!this._observers[property]) {
			this._observers[property] = function (e) {
				if (!e) { return; } // If a model manually emits a change, it may not have an event.
				if (e.type === 'model') {
					const propertyName = property + '.' + e.property;
					this.emitPropertyChange(propertyName, {
						newValue: e.newValue,
						oldValue: e.oldValue,
						observed: true,
						parentEvent: e,
					});
				} else if (e.type === 'list') {
					this.emitPropertyChange(property, {
						parentEvent: e,
						observed: true,
					});
				}
			}.bind(this);
		}
		obj.on('change', this._observers[property]);
	}

	unobserve(property /*: string */, obj /*: Model | ObservableList */) {
		obj.off('change', this._observers[property]);
	}

	copy(copyManager /*: any */) /*: any */ {

		// Create a copyManager if one isn't passed in
		// The copyManager keeps track of items already seen
		// when traversing a tree and returns the existing copy
		// if the same item is encountered again in the copy process
		if (!copyManager) {
			copyManager = new CopyManager();
		} else if (Array.isArray(copyManager)) {
			const list /*: Array<any> */ = copyManager;
			const instances = _clone(list);
			copyManager = new CopyManager(instances);
		}

		return copyManager.copy(this);
	}

	copyProperties(copyManager /*: any */) /*: any */ {
		const copiedProps = {};

		// Copy each property, using copyManager for model items
		this._modelDefinition.properties.forEach((property) => {
			if (property.shouldCopy(this)) {
				copiedProps[property.name] = property.copy(this, copyManager);
			}
		});

		return copiedProps;
	}

	toJSON(options = {}) {
		const json = {};
		options = {
			serializeUndefined: false,
			...options
		};

		this._modelDefinition.properties.forEach((property) => {
			const {customSerializer} = options;
			if (customSerializer) {
				customSerializer(property.name, this[property.name], this, json, this._serializeProperty.bind(this), options);
			} else {
				this._serializeProperty(property.name, this[property.name], json, options);
			}
		});

		return json;
	}

	_serializeProperty(key, value, json, options = {}) {

		const property = this._modelDefinition.getProperty(key);

		let jsonValue;
		if (property.shouldSerialize(this, options)) {
			jsonValue = property.getJSONValue(this, options);
			json[property.name] = jsonValue;
		}

		return jsonValue;
	}

	get _modelDefinition() /* : ModelDefinition */ {
		return this.constructor.modelDefinition;
	}

	static mixins() /*: Array<Object> */ {
		return [];
	}

	static fields() /*: Array<string> */ {
		return [];
	}

	static get modelDefinition() {
		this._init();
		return this._MODEL_DEFINITION_;
	}

	static properties() /*: Object */ {
		throw new Error(`Please override static properties() and provide properties for class "${this.name}".`);
	}

	static fromJSON(data, config) {
		let modelConfig = {parseJSON: true};
		if (config) {
			modelConfig = Object.assign({}, config, modelConfig);
		}
		return new this(data, modelConfig);
	}

	static get isModel() {
		return true;
	}

	static _init() {
		if (!this._MODEL_DEFINITION_ || this._MODEL_DEFINITION_.type !== this) {
			// Create and store the model definition
			this._MODEL_DEFINITION_ = new ModelDefinition(this);
			// Define properties on the prototype
			this._MODEL_DEFINITION_.defineProperties(this.prototype);
		}
	}

	static _globallyDisableSeal(val) {
		this._isSealedGloballlyDisabled = val;
	}

	/** Protected Members **/

	_doBroadcast(includeSelf, event, args) {
		this.visit((model) => {
			const isSelf = model === this;
			if (includeSelf || !isSelf) {
				model.emit(event, ...args);
			}
		});
	}

	_parseJSON(json /*: Object */, options /*: ToJSONOptions */ = {}) /*: Object */ {
		if (!json) { return {}; }

		const data = {};

		Object.keys(json).forEach(propertyName => {

			const property = this._modelDefinition.getProperty(propertyName);

			if (property && property.shouldDeserialize(this, options)) {
				data[property.name] = property.parseJSON(this, json);
			}

		});

		return data;
	}

	fromJSON() {
		throw new Error('instance fromJSON has been renamed to _parseJSON');
	}

	_getItemType(property /*: Property */, itemJSON /*: Object */) /*: function */ {
		return property.getItemType(itemJSON);
	}

	/** Private Methods **/
	_seal(config /*: ModelConfig */) {
		if (!(config.disableSeal || Model._isSealedGloballlyDisabled)) {
			Object.seal(this);
		}
	}

	_addFields() {
		this._modelDefinition.fields.forEach((field) => {
			const self /*: any */ = this;
			self[field] = undefined;
		});
	}

	_initMixins() {
		const types = [this.constructor].concat(this._modelDefinition.baseTypes);

		// Intialize the mixins at each level of the type hierarchy, starting from the highest parent on down
		_forEachRight(types, (type) => {

			type.modelDefinition.mixins.forEach((mixin) => {
				if (mixin._constructor) {
					mixin._constructor.apply(this);
				}
			});

		});
	}

	/**
	 * Resets the model to its original state. If properties or fields are provided, only reset those properties or fields.
	 * Passing in an empty array for either type will not reset anything for that type
	 * Not passing in anything for either type will reset everything for that type
	 * Not passing anything to reset will reset both props and fields
	 *
	 * @param {object} obj - filter object
	 * @param {Array} obj.properties - array of property names to reset
	 * @param {Array} obj.fields - array of field names to reset
	 *
	 * @param {object} obj - option object
	 * @param {Boolean} exclude - if set to true, resets everything but the list of properties or fields provided
	 */
	reset({properties, fields} = {}, {exclude = false} = {}) {

		let propsToReset;
		let fieldsToReset;

		if (exclude) {
			properties = properties || [];
			fields = fields || [];
			propsToReset = this._modelDefinition.properties.filter(el => !properties.includes(el.name));
			fieldsToReset = this._modelDefinition.fields.filter(el => !fields.includes(el));
		} else {

			if (!properties && !fields) {
				// we just reset everything

				fieldsToReset = this._modelDefinition.fields;
				propsToReset = this._modelDefinition.properties;

			} else {
				// only reset what is specified
				propsToReset = properties || [];
				fieldsToReset = fields || [];
			}
		}


		// set properties to default value or undefined
		propsToReset.forEach(property => {
			const modelProperty = typeof property === 'string' ? this._modelDefinition.getProperty(property) : property;
			const defaultValue = modelProperty.getDefaultValue();
			modelProperty.setValue(this, defaultValue);
		});

		// set fields to undefined
		fieldsToReset.forEach(field => {
			this[field] = undefined;
		});

	}

}

// Avoid circular dependency.
ObservableList.Model = Model;

function isBuiltIn(member) {
	return [Date, String, Boolean, Number, Array, Object].indexOf(member) > -1;
}

class ModelDefinition {

	/*::
		type: Class<Model>;
		ownProperties: Array<Property>;
		properties: Array<Property>;
		baseTypes: Array<Function>;
		fields: Array<String>;
		ownFields: Array<String>;
		mixins: Array<Object>;
		_propertiesByName: { [key: string] : Property };
	*/

	constructor(modelType /*: Function */) {
		this.type = modelType;
		this.mixins = this._getMixinsForType(modelType);
		this.baseTypes = this._getParentTypes(modelType);
		this.ownProperties = this._getOwnProperties(modelType, this.mixins);
		this.properties = this._getAllProperties(modelType, this.ownProperties);
		this._propertiesByName = this._getPropertiesByName(this.properties);
		this.ownFields = this._getOwnFields(modelType);
		this.fields = this._getAllFields(modelType, this.ownFields);
	}

	getBaseClass() {
		return this.baseTypes[0];
	}

	getProperty(name /*: string */) {
		return this._propertiesByName[name];
	}

	defineProperties(object /*: Object */) {

		// Add properties
		this.ownProperties.forEach((property) => {
			property.defineProperty(object);
		});

		const memberNames = _flatten([Model, this.type].concat(this.type.modelDefinition.baseTypes).map(type => Object.getOwnPropertyNames(type.prototype)));

		// Add mixin functions
		let mixinFunctionSpecs = this._getMixinFunctionSpecs();
		Object.keys(mixinFunctionSpecs).forEach(fnName => {
			let fnSpec = mixinFunctionSpecs[fnName];
			// Only add a mixin function if one of the parents doesn't define a
			// member with this name, we don't want mixins to override
			if (memberNames.indexOf(fnName) === -1) {
				Object.defineProperty(object, fnName, fnSpec);
			}
		});
	}

	hasMixin(mixin) {
		return this.mixins.indexOf(mixin) > -1;
	}

	_getOwnProperties(modelType, mixins) {
		const ownProperties = [];
		const propertySpecs = Object.assign({}, this._getMixinProperties(mixins), modelType.properties());
		Object.keys(propertySpecs).forEach(propertyName => {
			const propertySpec = propertySpecs[propertyName];
			if (propertySpec.setParent === undefined && modelType.options().setParent !== undefined) {
				propertySpec.setParent = modelType.options().setParent;
			}
			ownProperties.push(Property.create(
				propertyName,
				propertySpec,
			));
		});
		return ownProperties;
	}

	// Get just the properties of the mixin, not the functions
	_getMixinProperties(mixins) {
		const mixinProperties = {};
		mixins.forEach((mixin) => {
			Object.keys(mixin).forEach(mixinProp => {
				if (!this._isMixinFunction(mixin[mixinProp])) {
					mixinProperties[mixinProp] = mixin[mixinProp];
				}
			});
		});

		return mixinProperties;
	}

	_getMixinsForType(modelType) {
		const parentType = this._getParentType(modelType);
		// If this model isn't defining it's own mixins, return an empty list of mixins
		if (modelType.mixins === parentType.mixins) {
			return [];
		} else {
			return modelType.mixins();
		}
	}

	_isMixinFunction(value) {
		return (typeof value === 'function' && !value.isModel && !isBuiltIn(value)) || this._isGetterSetter(value);
	}

	_isGetterSetter(value) {
		return (_isPlainObject(value) && (typeof value.get === 'function' || typeof value.set === 'function'));
	}

	_getMixinFunctionSpecs() {
		const mixinFunctionSpecs = {};
		this.mixins.forEach((mixin) => {
			Object.keys(mixin).forEach(key => {
				let value = mixin[key];
				if (this._isGetterSetter(value)) {
					mixinFunctionSpecs[key] = value;
				} if (typeof value === 'function' && !value.isModel && !isBuiltIn(value)) {
					// Create a property definition for this function
					mixinFunctionSpecs[key] = { value: value };
				}
			});
		});
		return mixinFunctionSpecs;
	}

	_getAllProperties(modelType, ownProperties) {
		let allProperties = [];
		if (this.getBaseClass()) {
			allProperties = _clone(this.getBaseClass().modelDefinition.properties);
		}
		ownProperties.forEach(property => {
			const itemsToRemove = allProperties.filter(prop => prop.name === property.name);
			for (let i = itemsToRemove.length - 1; i >= 0; i--) {
				const item = itemsToRemove[i];
				allProperties.splice(allProperties.indexOf(item), 1);
			}
		});

		allProperties.push(...ownProperties);
		// Sort the properties in order by property name
		allProperties.sort((a, b) => a.name.localeCompare(b.name));
		return allProperties;
	}

	_getPropertiesByName(properties) {
		const propertiesByName = {};
		this.properties.forEach((property) => {
			propertiesByName[property.name] = property;
		});
		return propertiesByName;
	}

	_getOwnFields(modelType) {
		if (!Array.isArray(modelType.fields())) {
			throw Error(`${modelType.name}: fields() should return an array.`);
		}
		return modelType.fields();
	}

	_getAllFields(modelType, ownFields) {
		let allFields = [];
		if (this.getBaseClass()) {
			allFields = _clone(this.getBaseClass().modelDefinition.fields);
		}
		ownFields.forEach((field) => {
			if (allFields.indexOf(field) === -1) {
				allFields.push(field);
			}
		});
		return allFields;
	}

	_getParentTypes(modelType /*: function */, full /*: boolean */ = false) /*: Array<Function> */ {
		const types = [];
		let currentType = this._getParentType(modelType);
		while (currentType && currentType !== Object) {
			if (!full && currentType === Model) {
				break;
			}
			types.push(currentType);
			currentType = this._getParentType(currentType);
		}
		return types;
	}

	_getParentType(type) /*: function */ {
		return Object.getPrototypeOf(type.prototype).constructor;
	}
}

/*::
	type PropertySpec = {
		type: string,
		values?: Array<any>,
		silent?: boolean, // when true the property won't emit 'change' events
		transient?: boolean | string | Array<string>, // true to mark as transient, or a tag or array of tags to mark as transient and provide transient tags
		Array?: Model | Array<Model> | any,
		itemType?: Model | any,
		ensureExists?: boolean,
		onChange?: function, // function called with changeArgs and this set to the model when something changes
		observe: boolean,
		observeItems: boolean,
		readOnly: boolean,
		setParent: boolean,
		undoable: boolean
	};
 */

export class Property {

	/*::
		name: string;
		type: any;
		silent: boolean;
		values: Array<any>;
		transient: boolean;
		transientTags: Array<string>;
		isModelProperty: boolean;
		isArrayProperty: boolean;
		itemType: function;
		itemTypes: Array<function>;
		onChange: function;
		default: any;
		ensureExists: boolean;
		observe: boolean;
		observeItems: boolean;
		copyable: boolean;
		readOnly: boolean;
		const: boolean;
		schema: Object; // schema pass through for the json schema
		duplicates: string; // how to handle duplicates (move, prevent)
		serializeDefault: boolean;
		setParent: boolean; // if parent should be set when this property is set (or when a value is added to the array if this is an array property)
		undoable: boolean; //if the property will cause a change on the undo stack
		tags: Array<string>;
	*/

	static create(name /*: string */, spec /*: Object */) {

		let type = _isPlainObject(spec) ? spec.type : spec;

		if (Object.prototype.hasOwnProperty.call(spec, 'const')) {
			type = typeof spec.const;
		}

		if (!type && !spec.Array) {
			throw new Error(`You must provide a type for the property named: ${name}.`);
		}

		if (spec.Array || type === Array) {
			return new ArrayProperty(name, spec);
		} else if (type.isModel) {
			return new ModelProperty(name, spec);
		} else if (Array.isArray(type) && type.every(t => t.isModel)) {
			return new MultipleModelProperty(name, spec);
		} else if (type === Date) {
			return new DateProperty(name, spec);
		} else if (typeof type === 'string' && type.indexOf('enum') > -1) {
			return new EnumProperty(name, spec);
		} else {
			return new Property(name, spec);
		}

	}

	constructor(name /*: string */, spec /*: Object */) {
		this.name = name;
		this._setPropertiesFromSpec(spec);
	}

	createPropertyDefinition() /*: Object */ {
		const property = this;
		const validator = property._createValidator();

		/* 'this' in this function will be the calling object (e.g. the model) */
		/* property refers to this property (normal 'this') */
		const get = function () {
			if (this._data[property.name] === undefined && property.getDefaultValue(this) !== undefined) {
				set.call(this, property.getDefaultValue(this), true);
			}

			if (property.getter) {
				return property.getter(this._data[property.name], this);
			} else {
				return this._data[property.name];
			}
		};

		/* 'this' in this function will be the calling object (e.g. the model) */
		/* property refers to this property (normal 'this') */
		let set = function (value, silent) {
			if (property.setter) {
				value = property.setter(value, this);
			}

			validator.ensureValid(property.name, value);

			// Set the value
			const oldValue = this._data[property.name];

			if (oldValue === value) {
				return;
			}

			value = property.setValue(this, value);

			// If the list is already defined, we can skip the rest
			if (property.isArrayProperty && oldValue) {
				return;
			}

			const changeArgs = {newValue: value, oldValue: oldValue};
			if (property.onChange) { property.onChange.call(this, changeArgs); }

			// Fire change event
			if (!property.silent && !property.isArrayProperty && !silent) {
				this.emitPropertyChange(property.name, changeArgs);
			}

			if ((property.isModelProperty || property.isArrayProperty) && property.observe) {
				if (oldValue) {
					this.unobserve(property.name, oldValue);
				}
				if (value) {
					this.observe(property.name, value);
				}
			}
		};

		const definition = {
			enumerable: true,
			configurable: true,
			get: get,
			set: set,
		};

		if (this.readOnly) {
			delete definition.set;
		}

		return definition;
	}

	getDefaultValue(model) {
		if (typeof this.default === 'function') {
			return this.default.call(this);
		} else {
			return this.default;
		}
	}

	setValue(model, value) {
		model._data[this.name] = value;
		return model._data[this.name];
	}

	defineProperty(object /*: Object */) {
		Object.defineProperty(
			object,
			this.name,
			this.createPropertyDefinition(),
		);
	}

	shouldSerialize(model /*: any */, options /*: any */) {
		const hasDefaultValue              = () => this.getDefaultValue(model) === model[this.name];
		const passesSerializeDefaultCheck  = this.serializeDefault || !hasDefaultValue();
		const passesTransientCheck         = !this.transient || this.transientMatch(options.transient);
		const passesUndefinedCheck         = options.serializeUndefined || model[this.name] !== undefined;
		return passesTransientCheck && passesSerializeDefaultCheck && passesUndefinedCheck;
	}

	shouldDeserialize(model /*: any */, options /*: any */) {
		return (!this.transient || this.transientMatch(options.transient));
	}

	parseJSON(model, json) {
		return json[this.name];
	}

	transientMatch(transient /*: boolean | string | Array<string> */) {
		// If transient is true or any of the tag/tags provided are included
		// in this properties transient tags, then there is a match
		const transientInfo = this._parseTransient(transient);
		let isTransient = transientInfo.transient === true;
		if (!isTransient) {
			transientInfo.transientTags.forEach(tag => {
				if (this.transientTags.includes(tag)) {
					isTransient = true;
				}
			});
		}
		return isTransient;
	}

	getJSONValue(model /*: any */) {

		return model[this.name];

	}

	getItemType(itemJSON) {
		throw new Error(`${this.name}: getItemType not supported for non array properties.`);
	}

	shouldCopy(model) {
		return this.copyable !== false;
	}

	copy(model /*: any */, copyManager /*: any */) {
		return _clone(model[this.name]);
	}

	_createValidator() {
		return Validator.create(this.type);
	}

	/** Private Members **/
	_setPropertiesFromSpec(spec /*: any */) {

		if (_isPlainObject(spec)) {
			this.type = spec.type;
			this.silent = spec.silent;
			this.transient = this._parseTransient(spec.transient).transient;
			this.transientTags = this._parseTransient(spec.transient).transientTags;
			this.values = spec.values || [];
			this.onChange = spec.onChange;
			this.default = spec.default;
			this.observe = spec.observe;
			this.observeItems = spec.observeItems;
			this.copyable = spec.copyable;
			this.readOnly = spec.readOnly;
			this.getter = spec.getter;
			this.setter = spec.setter;

			// Default values will be serialized unless serializeDefault is set to false.
			// serializeDefault can be set to "false" to reduce the size of JSON resulting from toJSON().
			// The risk of setting it to "false" is if you ever change the default value in the future, any
			// JSON previously saved will now have a new value for the property, which is usually not the
			// desired behavior.
			if (spec.serializeDefault === undefined) {
				this.serializeDefault = true;
			} else {
				this.serializeDefault = spec.serializeDefault;
			}

			this.duplicates = spec.duplicates;
			this.setParent = spec.setParent;
			this.undoable = spec.undoable;
			this.schema = spec.schema;
			this.tags = spec.tags;
			if (spec.const !== undefined) {
				this.readOnly = true;
				this.default = spec.const;
				this.const = true;
				this.type = typeof spec.const;
				this.serializeDefault = true;
			}
		} else {
			this.type = spec;
		}

	}

	_parseTransient(transient /*: any */) {
		const result = {
			transient: transient !== undefined,
			transientTags: [],
		};
		if (Array.isArray(transient)) {
			result.transientTags = transient;
		} else if (typeof transient === 'string') {
			result.transientTags = [transient];
		}
		return result;
	}

	visit(model, callback, visited) {
	}

	_replace(model, callback) {
	}
}

class ModelProperty extends Property {

	constructor(name /*: string */, spec /*: Object */) {
		super(name, spec);
		this.isModelProperty = this.type.isModel;
		this.ensureExists = spec.ensureExists;
	}

	shouldSerialize(model, options) {
		const passesUndefinedCheck = options.serializeUndefined || model[this.name];
		return passesUndefinedCheck && super.shouldSerialize(model, options);
	}

	parseJSON(model, json) {
		const value = json[this.name];

		//in certain cases we want to get json properties that are undefined/null, see AN-156117
		return value ? this.type.fromJSON(value) : value;
	}

	setValue(model, value /*: ?Model */) {
		if (value && this.setParent !== false) {
			value.parent = model;
		}
		return super.setValue(model, value);
	}

	getJSONValue(model /*: any */, options = {}) {
		const value = model[this.name];

		//in certain cases we want to get json properties that are undefined/null, see AN-156117
		return value ? value.toJSON({...options, embedded: true}) : value;
	}

	getDefaultValue(model) {
		if (this.ensureExists) {
			return new this.type();
		} else {
			return super.getDefaultValue(model);
		}
	}

	shouldCopy(model /*: any */) {
		return super.shouldCopy() && model[this.name];
	}

	copy(model /*: any */, copyManager) {
		return copyManager.copy(model[this.name]);
	}

	visit(model, callback, options) {
		const value = model[this.name];
		if (value) {
			value.visit(callback, Object.assign({}, options, {
				context: {parent: model, property: this.name},
			}));
		}
	}

	_replace(model, callback) {
		const value = model[this.name];
		if (value) {
			const newValue = callback(value);
			model[this.name] = newValue;
			// If the value isn't replaced, replace sub items
			if (value === newValue) {
				value._replace(callback);
			}
		}
	}

}

class MultipleModelProperty extends ModelProperty {

	constructor(name /*: string */, spec /*: Object */) {
		super(name, spec);
		this.isModelProperty = this.type.every(t => t.isModel);
		this.isMultipleModelProperty = true;
	}

	getDefaultValue(model) {
		// Don't do ensureExists for MultipleModel type because we don't know what to create,
		// same thing can be done with default funciton
		return super.getDefaultValue(model);
	}

	parseJSON(model, json) {
		const propJSON = json[this.name];

		//in certain cases we want to get json properties that are undefined/null, see AN-156117
		return propJSON ? model._getItemType(this, propJSON).fromJSON(propJSON) : propJSON;
	}

	getItemType(json) {
		const types = this.type.filter(Type => Type.canParse && Type.canParse(json));
		if (types.length === 0) {
			throw new Error(`${this.name}: None of the provided types can parse this json`);
		} else {
			return types[0];
		}
	}

}

class ArrayProperty extends Property {

	/*::
		isModelList: boolean;
	*/

	constructor(name /*: string */, spec /*: Object */) {
		super(name, spec);
		this.type = Array;
		this.isArrayProperty = true;
		this.itemType = spec.Array;
		if (this.itemType === undefined) {
			throw new Error(`${this.name}: Property type Array must have a type. For example, instead of prop: Array, use prop:{Array: String}`);
		}
		this.itemTypes = Array.isArray(this.itemType) ? this.itemType : [this.itemType];
		const itemTypes = Array.isArray(this.itemType) ? this.itemType : [this.itemType];
		this.isModelList = itemTypes.every(type => type.isModel);
	}

	getItemType(itemJSON) {
		if (this.itemType && this.itemType.isModel) {
			return this.itemType;
		} else if (Array.isArray(this.itemType)) {
			const itemTypes = this.itemType.filter(ItemType => ItemType.canParse && ItemType.canParse(itemJSON));
			if (itemTypes.length === 0) {
				throw new Error(`${this.name}: None of the provided item types can parse this json`);
			} else {
				return itemTypes[0];
			}
		} else {
			throw new Error(`${this.name}: itemType is not valid.`);
		}
	}

	parseJSON(model, json) {
		const list = json[this.name];

		if (!list) { return []; }

		if (!Array.isArray(list)) {
			throw Error(`${this.name}: Expected value to be a list, but it was ${typeof json}`);
		}

		if (isBuiltIn(this.itemType)) {
			return list.concat();
		}

		return list.map((itemJSON) => {
			const ItemType = model._getItemType(this, itemJSON);
			return ItemType.fromJSON(itemJSON);
		});
	}

	getJSONValue(model /*: any */, options = {}) {
		// Just pass through arrays of built-in types
		if (isBuiltIn(this.itemType)) {
			return super.getJSONValue(model, options);
		} else {
			const list = model[this.name];
			const json = [];
			const {customSerializer} = options;

			// For arrays of models, we want call toJSON on each item in the array
			list.forEach((item, index) => {
				if (customSerializer) {
					customSerializer(index, item, model, json, this._serializeItem.bind(this), options);
				} else {
					this._serializeItem(index, item, json, options);
				}
			});

			return json;
		}
	}

	_serializeItem(key, item, json, options = {}) {
		const jsonValue = item.toJSON({...options, embedded: true});
		json[key] = jsonValue;
		return jsonValue;
	}

	getDefaultValue(model) {
		let value = this.default;
		if (typeof value === 'function') {
			value = this.default();
		}
		return this._createList(model, value);
	}

	setValue(model, value) {
		if (!value) { return value; }

		const list = model._data[this.name];

		if (list) {
			list.replaceAll(value);
			return list;
		} else {
			model._data[this.name] = this._createList(model, value);
			return model._data[this.name];
		}
	}

	copy(model /*: any */, copyManager /*: CopyManager */) {
		if (isBuiltIn(this.itemType)) {
			return super.copy(model, copyManager);
		} else {
			return model[this.name].map(item =>  copyManager.copy(item));
		}
	}

	_createList(model, values) /*: ObservableList */ {
		const config = Object.assign({}, this, {parent: model});
		return new ObservableList(values, config);
	}

	visit(model, callback, options) {
		const list = model[this.name];
		if (list) {
			if (options.visitArrayInstances) {
				callback(list, options.context);
			}
			list.forEach((item) => {
				if (item instanceof Model) {
					item.visit(callback, Object.assign({}, options, {
						context: {parent: model, property: this.name},
					}));
				}
			});
		}
	}

	_replace(model, callback) {
		if (this.isModelList) {
			const list = model[this.name];
			if (!list) {
				throw new Error(`${this.name}: Undefined list error. Most likely you have a property in a base class that is an array and the child class overwrites the property with a getter.`);
			}
			const newList = list.map((item) => {
				const newItem = callback(item);
				// if it wasn't replaced, then traverse the item and replace subitems
				if (newItem === item) {
					item._replace(callback);
				}
				return newItem;
			});
			model[this.name] = newList;
		}
	}

}

class EnumProperty extends Property {

	constructor(name /*: string */, spec /*: Object */) {
		super(name, spec);
		if (typeof spec === 'string') {
			spec = {
				type: spec,
			};
		}
		this.type = 'enum';
		this.values = spec.values || this._parseEnumValues(spec.type);
	}

	_createValidator() {
		return Validator.create({type: this.type, values: this.values});
	}

	_parseEnumValues(enumExpression) {
		const startIndex = enumExpression.indexOf('(');
		const endIndex = enumExpression.indexOf(')');
		const enumValues = enumExpression.substring(startIndex + 1, endIndex);
		return enumValues.split(',').map(function (value) { return value.trim(); });
	}
}

class DateProperty extends Property {

	parseJSON(model, json) {
		const dateStr = json[this.name];
		return dateStr === null ? null : new Date(dateStr);
	}

}

// The copyManager keeps track of items already seen
// when traversing a tree and returns the existing copy
// if the same item is encountered again in the copy process
export class CopyManager {

	/*::
		_instances: Map;
	*/

	constructor(knownInstances) {
		this._instances = new Map();

		knownInstances = knownInstances || [];
		knownInstances.forEach((instance) => {
			this._instances.set(instance, instance);
		});
	}

	copy(instance /*: any */) {
		if (!this._instances.has(instance)) {

			// Create the instance first and add it to the list before copying properties
			const Type = instance.constructor;
			const copy = new Type({}, {copying: true});
			this._instances.set(instance, copy);

			// Now we can copy properties that might reference this instance
			copy.setData(instance.copyProperties(this));

			if (instance._postCopy) { instance._postCopy(copy); }

			// Let the copied model know copying is complete
			copy.emit('copied');
		}
		return this._instances.get(instance);
	}

}

Model.Property = Property;
