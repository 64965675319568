//this will allow you to parse json line files.
//more information at: http://jsonlines.org/
export default class jsonLines {

  //Each Line is a Valid JSON Value
  //in the case of badly formed json, it is presumed the caller will catch and deal with the error
  static convertTexttoArray(data) {

    let lines = data.split('\n');
    let json = [];
    lines.forEach((line) => {
      if (line.length) {
        json.push(JSON.parse(line));
      }
    });
    return json;
  }

}
