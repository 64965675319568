import _ from './_';
import { l10n } from '@analytics/l10nify';
import moment from './moment';
import OmniDate from './OmniDate';


let DEFAULT_RANGE_FORMULA = 'tm/tm+1m';
let SECOND = 'variables/daterangesecond';
let MINUTE = 'variables/daterangeminute';
let HOUR = 'variables/daterangehour';
let DAY = 'variables/daterangeday';
let WEEK = 'variables/daterangeweek';
let MONTH = 'variables/daterangemonth';
let QUARTER = 'variables/daterangequarter';
let YEAR = 'variables/daterangeyear';

const formatMapping = {
	'variables/daterangeminute':  'YYYY-MM-DDTHH:mm:00',
	'variables/daterangehour':    'YYYY-MM-DDTHH:00:00',
	'variables/daterangeday':     'YYYY-MM-DD',
	'variables/daterangeweek':    'YYYY-MM-DD',
	'variables/daterangemonth':   'YYYY-MM-DD',
	'variables/daterangequarter': 'YYYY-MM-DD',
	'variables/daterangeyear':    'YYYY-MM-DD',
};

const granularityMapping = {
	'variables/daterangeminute':  'minute',
	'variables/daterangehour':    'hour',
	'variables/daterangeday':     'day',
	'variables/daterangeweek':    'week',
	'variables/daterangemonth':   'month',
	'variables/daterangequarter': 'quarter',
	'variables/daterangeyear':    'year',
};

const granularityList = [
	{ dimensionId: 'variables/daterangeminute', label: l10n('Minute') },
	{ dimensionId: 'variables/daterangehour', label: l10n('Hour') },
	{ dimensionId: 'variables/daterangeday', label: l10n('Day') },
	{ dimensionId: 'variables/daterangeweek', label: l10n('Week') },
	{ dimensionId: 'variables/daterangemonth', label: l10n('Month') },
	{ dimensionId: 'variables/daterangequarter', label: l10n('Quarter') },
	{ dimensionId: 'variables/daterangeyear', label: l10n('Year') },
];

const granularityInverseMapping = {
	minute:  'variables/daterangeminute',
	hour:    'variables/daterangehour',
	day:     'variables/daterangeday',
	week:    'variables/daterangeweek',
	month:   'variables/daterangemonth',
	quarter: 'variables/daterangequarter',
	year:    'variables/daterangeyear',
};

// This is used to parse preset formulas.
const formulaMapping = {
	y: 'year',
	q: 'quarter',
	m: 'month',
	w: 'week',
	d: 'day',
	h: 'hour',
	i: 'minute',
};

const rollingStringLabels = {
	td: l10n('rolling daily'),
	tw: l10n('rolling weekly'),
	tm: l10n('rolling monthly'),
	tq: l10n('rolling quarterly'),
	ty: l10n('rolling yearly'),
};

const rangeTypeByFormulaId = {
	ti: 'minute',
	th: 'hour',
	td: 'day',
	tw: 'week',
	tm: 'month',
	tq: 'quarter',
	ty: 'year',
};

const YYYMMDDHHFormatMapping = {
	3: 'YYYY',
	5: 'MMM YYYY',
	7: 'll',
	9: 'lll',
	11: 'lll',
};

const YYYMMDDHHFormatMappingForDimensionItems = {
	'variables/daterangeminute':  'lll',
	'variables/daterangehour':    'lll',
	'variables/daterangeday':     'll',
	'variables/daterangeweek':    'll',
	'variables/daterangemonth':   'MMM YYYY',
	'variables/daterangequarter': 'MMM YYYY',
	'variables/daterangeyear':    'YYYY',
};

const rangeStringFormat = 'YYYY-MM-DDTHH:mm:ss';

let globalReferenceDate = (typeof (window)  === 'undefined') ? undefined : _.get(window, 'adobe.analytics.appConfig.referenceDate');

function parseYYYMMDDHH(str) {
	// get the year, month, and day
	let ymd = parseInt(str, 10);

	// Convert to full digits.
	if (ymd < 1000) {          ymd *= 10000000000; }     // Year only (107)
		else if (ymd < 100000) { ymd *= 100000000; }     // Month only (10706)
		else if (ymd < 10000000) { ymd *= 1000000; }     // Day only (1070601)
		else if (ymd < 1000000000) { ymd *= 10000; }     // Hour only (107060101)
		else if (ymd < 100000000000) { ymd *= 100; }     // Minute only (10700020304)
		const y = parseInt(ymd / 10000000000, 10) + 1900;
		const m = parseInt(ymd / 100000000, 10) % 100; // How did this ever work in omnidate.js? It added an additional "1" to the result.
		let d = parseInt(ymd / 1000000, 10) % 100;
		const h = parseInt(ymd / 10000, 10) % 100;
		const i = parseInt(ymd / 100, 10) % 100;
		const s = ymd % 100;
	if (!d) { d = 1; } // if day was left blank, set it to the first day, ( you never have Jan 0. )

	return moment(new Date(y, m, d, h, i, s));
}

function formatYYYMMDDHH(str, dimensionId) {
	const formatStr = dimensionId ?  YYYMMDDHHFormatMappingForDimensionItems[dimensionId] : YYYMMDDHHFormatMapping[_.get(str, 'length', 0)]; // Use the length of the fragid to determine the format.
	return parseYYYMMDDHH(str).format(formatStr || 'lll');
}

function getGranularityList() {
	return granularityList;
}

function getGranularityFromItemId(itemId) {
	return granularityMapping[itemId];
}

function getGranularityIdFromItem(item) {
	return granularityInverseMapping[item];
}

// Currently everything uses a generic date format of {year: 2015, month: 0, day:1}.
// This format can be parsed directly from moment. If needed we can change this format later on.
function DateService(reportSuite) {
	if (!reportSuite) { throw new Error('You must pass in a reportSuite to the DateService constructor!'); }
	const that = this;

	// For now we use omnidate. In the future we can take it out, and we would only have to update these first few methods.
	that.omniDate = new OmniDate(reportSuite.calendarType.type, moment(reportSuite.calendarType.anchorDate, 'YYYY-MM-DD'));

	this.setCalendarType = function (type, anchor) {
		that.omniDate = new OmniDate(type, anchor);
	};

	this.getFirstWeekDay = function () {
		return that.omniDate.getFirstWeekday();
	};

	/******************************************************
	* Range Selection
	******************************************************/

	this.currentRange = function (date, rangeType, fillInHoursMinutesSeconds) { // year, quarter, month, week, day
		date = date || undefined; // Moment doesn't like it when date is null. An undefined date defaults to today, so we use that instead.
		const range = that.omniDate['getCurrent' + capitalizeFirstLetter(rangeType)](date);
		if (fillInHoursMinutesSeconds) {
			if (_.contains(['year', 'quarter', 'month', 'week', 'day'], rangeType)) {
				range.end.endOf('day');
			}
		}
		return range;
	};

	this.range = function (granularity, start) {
		if (!granularity) {
			throw new Error("You must pass in a granularity");
		}
		start = start || this.now();
		return this.currentRange(start, granularity);
	};

	this.nextRange = function (day, rangeType) {
		const range = this.currentRange(day, rangeType);
		return this.currentRange(moment(range.end).add(1, this._getRangeAdjustmentType(rangeType)), rangeType);
	};

	this.previousRange = function (day, rangeType) {
		const range = this.currentRange(day, rangeType);
		return this.currentRange(moment(range.start).subtract(1, this._getRangeAdjustmentType(rangeType)), rangeType);
	};

	this._getRangeAdjustmentType = function (rangeType) {
		if (_.contains(['hour', 'minute'], rangeType)) {
			return 'seconds';
		}
		return "days";
	};

	// Internal function for calling methods.
	function capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	// Converts a range with a start and end to be an array of day objects.
	this.getDaysAsArray = function (range) {
		const start = range.start;
		const end = range.end;
		const result = [];
		let mStart = moment(start);
		let mEnd = moment(end);
		if (mStart.isSame(mEnd, 'day')) { // if only one day is selected, don't include it twice.
			return [start];
		}
		if (!mStart.isValid() || !mEnd.isValid() || mStart.isAfter(mEnd)) {
			return [];
		}
		while (!mStart.isAfter(mEnd)) { // add all the days for the range, adding one day at a time.
			result.push(mStart.clone());
			mStart = mStart.add(1, 'days');
		}
		return result;
	};

	/******************************************************
	* RangeString Methods
	******************************************************/

	// Take a range that has a start and end and format it as a RangeString.
	this.getRangeStringFromRange = function (range, forceFixedDates) {
		if (!range) { return ''; }
		if (range.formula && forceFixedDates !== true) {
			return range.formula;
		}
		if (!moment.isMoment(range.start)) { range.start = moment(range.start); }
		if (!moment.isMoment(range.end)) { range.end = moment(range.end); }

		return range.start.format(rangeStringFormat) + '/' + range.end.format(rangeStringFormat);
	};

	this.getDefaultRangeString = function () {
		return DEFAULT_RANGE_FORMULA;
	};

	this.getRangeFromRangeString = function (rangeString) {
		return this.parsePresetRange(rangeString);
	};

	this.getRangeFromPresetId = function (presetId) {
		return this.getRangeFromRangeString(this.getPresets()[presetId].value);
	};

	/*
	* Get a fixed range string for any type of date string passed in.
	* Fixed means fixed start and end dates, like: '2016-03-01T00:00:00/2016-04-01T00:00:00'
	*
	* Some examples:
	*  Input: 'tm/tm+1'                  Output: '2016-03-01T00:00:00/2016-04-01T00:00:00'
	*  Input: 'tm/2016-04-01T00:00:00'   Output: '2016-03-01T00:00:00/2016-04-01T00:00:00'
	*  Input: '2016-03-01T00:00:00/tm+1' Output: '2016-03-01T00:00:00/2016-04-01T00:00:00'
	*
	* @param {String} rangeString
	* @param {Boolean} makeEndDateExclusive - pass true to push the end date 1 ms forward, to be midnight of the next day.
	*/
	this.getFixedRangeStringFromRangeString = function (rangeString, makeEndDateExclusive) {
		const dateRange = this.getRangeFromRangeString(rangeString);

		if (makeEndDateExclusive) {
			if (dateRange.end.milliseconds() === 999) {
					dateRange.end.milliseconds(1000);
			} else if (dateRange.end.seconds() === 59 && dateRange.end.milliseconds() === 0) {
					dateRange.end.seconds(60);
			}
		}

		return this.getRangeStringFromRange(dateRange, true);
	};

	this.getStandardFormat = function (momentDate) {
		return momentDate.format(rangeStringFormat);
	};

	this.getMomentFromStandardFormat = function (str) {
		return moment(str, rangeStringFormat);
	};

	// Takes a date range and formats the start and end according to the provided format with a - between them.
	this.formatRange = function (dateRange, format) {
		format = format || 'll';
		dateRange = _.isString(dateRange) ? this.getRangeFromRangeString(dateRange) : dateRange; // If it is a string, convert to a date range object.
		const $start = moment(dateRange.start);
		const $end = moment(dateRange.end);
		return $start.format(format) + ' - ' + $end.format(format);
	};

	this.formatForCSV = function (val, id) {
		if (!formatMapping[id]) {
			return val;
		}
		return moment(val).format(formatMapping[id]);
	};

	/******************************************************
	* Presets
	******************************************************/

	// Example preset: td-7d/td      last full 7 days
	this.parsePresetRange = function (formula, referenceDate) {
		// Allow pinning the referenceDate for testing / offline development
		// Reference date is not set for the web version. It is only set when rendering PDFs.
		// If it is not set, we should use that.now() in order to take into account timezone differences, such as when the report suite is already in the next day.
		referenceDate = referenceDate || that.now();
		const range = formula.split('/');
		const start = this.parsePresetDate(range[0], referenceDate);
		let end = this.parsePresetDate(range[1], referenceDate, true);
		if (!this.isFormulaFixedDate(range[1])) {
			end.add(-1, 'days'); // since the calendar uses the entire day and not the start of the day, we need to go back 1
		}
		if (end.isBefore(start, 'day')) {
			end = start.clone(); // if the end was going to move before the start, then put it back to where it was. This happens when they are the same day.
			setHoursMinutesSeconds(end, true); // Set to the end of the current day.
		}
		if (!this.isRangeStringAFormula(formula)) { // if they are both fixed dates, it is not really a formula.
			formula = '';
		}
		return {start:start, end:end, formula: formula};
	};

	this.isRangeStringAFormula = function (rangeString) {
		if (!rangeString) {
			return false;
		}
		const range = rangeString.split('/');
		return !(this.isFormulaFixedDate(range[0]) && this.isFormulaFixedDate(range[1])); // if both are fixed dates, it is not a formula.
	};

	this.isFormulaFixedDate = function (formula) {
		if (!formula) {
			return true;
		}
		return formula.length && formula[0] !== 't';
	};

	function setHoursMinutesSeconds(date, isEnd) {
		return isEnd ? date.hour(23).minute(59).second(59).millisecond(999) : date.hour(0).minute(0).second(0).millisecond(0);
	}

	this.getGranularityList = getGranularityList;

	this.parseYYYMMDDHH = function (str) {
		return parseYYYMMDDHH(str);
	};

	this.formatYYYMMDDHH = function (str, dimensionId) {
		return formatYYYMMDDHH(str, dimensionId);
	};


	this.getYYYMMDDHH = function (dateStr, granularity, returnAsString = false) {
		const includeHour = granularity === 'hour' || granularity === 'minute';
		const includeMinute = granularity === 'minute';
		const date = moment(dateStr);

		const year = date.year() - 1900;
		const month = date.month();
		const day = date.date();
		const hour = date.hour();
		const minute = date.minute();

		let fragStr = _.padLeft(year, 3, '0') + _.padLeft(month, 2, '0') + _.padLeft(day, 2, '0');
		if (includeHour) {
			fragStr += _.padLeft(hour, 2, '0');
		}
		if (includeMinute) {
			fragStr += _.padLeft(minute, 2, '0');
		}

		return returnAsString ? fragStr : parseInt(fragStr, 10);
	};

	this.getRangeFromItemId = function (itemId, dimensionId) {
		const start = this.parseYYYMMDDHH(itemId);
		const end = this.add(start, 1, granularityMapping[dimensionId]);
		end.add(-1, 'milliseconds');
		return {start, end};
	};

	// Takes a str formatted as 1150601D29 or just 11506
	this.parseYYYMMDDHHPeriod = function (str) {
		str += '';
		const periodPieces = str.split('D');
		const start = this.parseYYYMMDDHH(periodPieces[0]);
		let end;

		if (periodPieces.length === 1) { // There was no "D", so we need to select the entire period.
			const periodAsAString = periodPieces[0] + '';
			switch (periodAsAString.length) {
				case 3:
					end = moment(start).endOf('year');
					break;
				case 5:
					end = moment(start).endOf('month');
					break;
				case 7:
					end = moment(start).endOf('day');
					break;
				case 9:
					end = moment(start).endOf('hour');
					break;
				case 11:
					end = moment(start).endOf('minute');
					break;
			}
		} else {
			end = moment(start).add(+periodPieces[1] - 1, 'days').endOf('day');
		}


		return {start: start, end:end};
	};


	// Given a range {start, end}, and an array of date ranges, find the intersection of them all.
	// Assumes all dates are moment types.
	this.getIntersectionOfDateRanges = function (...args) {
		const otherDateRanges = _.flatten(args);
		const existingRange = otherDateRanges[0];
		const intersectingRange = {start: existingRange.start, end: existingRange.end};
		_.each(otherDateRanges, function (newRange) {
			if (newRange.start.isAfter(intersectingRange.start)) { // the new date is after the start date, which means the start can be adjusted to the new date.
				intersectingRange.start = newRange.start;
			}
			if (newRange.end.isBefore(intersectingRange.end)) {
				intersectingRange.end = newRange.end;
			}
		});
		return intersectingRange;
	};

	// Parse a single date from a formula, such as td-7d
	this.parsePresetDate = function (formula, referenceDate, isEnd) {
		referenceDate = referenceDate || that.now();

		if (!formula) {
			return setHoursMinutesSeconds(moment(), isEnd);
		}

		// If the formula does not start with a t it is a fixed date. Fixed dates can't have additional formulas, since they will end up being a fixed date.
		if (this.isFormulaFixedDate(formula)) {
			return moment(formula);
		}

		formula = formula.replace(/-/gi, '+-'); //add a + infront of all - to make it easier

		let result;
		const directions = formula.split('+');
		_.each(directions, function (direction) {
			let quantity = direction.length <= 1 ? direction : direction.slice(0, -1); // If there is nothing, or it is just one character, the character is the quantity. Otherwise, everything except the last character is the quantity.
			let method = 'nextRange';
			if (quantity < 0) {
				method = 'previousRange';
				quantity = Math.abs(quantity);
			}

			const unit = formulaMapping[direction.slice(-1)]; // the last character is the unit, such as d,m,q,y

			if (quantity === 't') {
				result = that.currentRange(referenceDate, unit).start; // By definition, "t" means "to the start of", so we get the start of the month, or week, etc.
			} else if (unit === 'day') { // Optimization to handle many day changes.
					if (method === 'previousRange') {
						result.add(quantity * -1, 'days');
					} else {
						result.add(+quantity, 'days');
					}
				} else {
					_.times(quantity, function () {
						result = that[method](result, unit).start; //If there is a quantity, we modify the result that many times.
					});
				}
		});

		setHoursMinutesSeconds(result, isEnd); // For now, either clear out the time or set it to the end of the day.

		return result;
	};

	this.add = function (date, quantity, granularity) { // granularity = day, week, month
		let result = moment(date);
		if (quantity === 0) {
			return result;
		}

		if (_.contains(['second', 'minute', 'hour', 'day', 'week'], granularity)) {
			return this.addWeekOrLessWithRespectToDaylightSavings(date, quantity, granularity);
		}

		const method = quantity > 0 ? 'nextRange' : 'previousRange';
		_.times(Math.abs(quantity), () => {
			result = this[method](result, granularity).start; //If there is a quantity, we modify the result that many times.
		});
		return result;
	};

	// Because of daylight savings, if the amount is greater than a day, we add an entire day.
	// This will keep the hour the same. If you add 24 hours instead of a day, then if you are adding across a DST, then it will be off by an hour.
	this.addWeekOrLessWithRespectToDaylightSavings = function (date, quantity, granularity) {
		const $date = moment(date);

		if (granularity === 'hour' && Math.abs(quantity) >= 24) {
			const days = parseInt(quantity / 24, 10);
			quantity -= days * 24;
			$date.add(days, 'days');
		}

		if (granularity === 'minute' && Math.abs(quantity) >= 1440) {
			const days = parseInt(quantity / 1440, 10);
			quantity -= days * 1440;
			$date.add(days, 'days');
		}

		if (granularity === 'second' && Math.abs(quantity) >= 86400) {
			const days = parseInt(quantity / 86400, 10);
			quantity -= days * 86400;
			$date.add(days, 'days');
		}

		return $date.add(quantity, granularity);
	};


	this.getDateDescription = function (stringValue) {
		// Check to see if it is a preset, and return it. Otherwise, return if it is fixed or rolling.
		const preset = this.getDateRangeByValue(stringValue);
		if (preset) {
			return '( ' +  preset.label + ' )';
		}

		return this.getRollingString(stringValue);
	};

	this.getPresets = function () {
		/*
			*	https://wiki.corp.adobe.com/display/scservices/Rolling+Date+Ranges
			*	Easiest way to understand: td, tw, tm, tq, and ty = Start of This Day, This Week, This Month...
			*	If there is not a value after the colon, it *should* mean 'now'.
			*	tw/td           Week to start of today
			*	td-7d/td        Last full 7 days
			*	ty-1y/td-1y     Year-to-date, 1 year ago
			*	tq-1q-1w/tq-1q The week before the start of last quarter
			*/

		return {
			today:            {id: 'today',   value: 'td/td+1d',     label:l10n('Today') },
			yesterday:        {id: 'yesterday',   value: 'td-1d/td',     label:l10n('Yesterday') },
			twoDaysAgo:       {id: 'twoDaysAgo',  value: 'td-2d/td-1d',  label:l10n('2 days ago') },
			threeDaysAgo:     {id: 'threeDaysAgo',  value: 'td-3d/td-2d',  label:l10n('3 days ago') },
			last7Days:        {id: 'last7Days',   value: 'td-6d/td+1d',  label:l10n('Last 7 days') },
			last14Days:       {id: 'last14Days',   value: 'td-13d/td+1d', label:l10n('Last 14 days') },
			last30Days:       {id: 'last30Days',   value: 'td-29d/td+1d', label:l10n('Last 30 days') },
			last60Days:       {id: 'last60Days',   value: 'td-59d/td+1d', label:l10n('Last 60 days') },
			last90Days:       {id: 'last90Days',   value: 'td-89d/td+1d', label:l10n('Last 90 days') },
			thisWeek:         {id: 'thisWeek',   value: 'tw/tw+1w',     label:l10n('This week') },
			lastFullWeek:     {id: 'lastFullWeek',   value: 'tw-1w/tw',     label:l10n('Last week') },
			twoWeeksAgo:      {id: 'twoWeeksAgo',  value: 'tw-2w/tw-1w',  label:l10n('2 weeks ago') },
			threeWeeksAgo:    {id: 'threeWeeksAgo',  value: 'tw-3w/tw-2w',  label:l10n('3 weeks ago') },
			last2FullWeeks:   {id: 'last2FullWeeks',  value: 'tw-2w/tw',     label:l10n('Last 2 full weeks') },
			last3FullWeeks:   {id: 'last3FullWeeks',  value: 'tw-3w/tw',     label:l10n('Last 3 full weeks') },
			last4FullWeeks:   {id: 'last4FullWeeks',  value: 'tw-4w/tw',     label:l10n('Last 4 full weeks') },
			last53FullWeeks:  {id: 'last53FullWeeks',  value: 'tw-53w/tw',    label:l10n('Last 53 full weeks') },
			thisMonth:        {id: 'thisMonth',  value: 'tm/tm+1m',     label:l10n('This month') },
			lastFullMonth:    {id: 'lastFullMonth',  value: 'tm-1m/tm',     label:l10n('Last month') },
			twoMonthsAgo:     {id: 'twoMonthsAgo', value: 'tm-2m/tm-1m',  label:l10n('2 months ago') },
			threeMonthsAgo:   {id: 'threeMonthsAgo', value: 'tm-3m/tm-2m',  label:l10n('3 months ago') },
			last2FullMonths:  {id: 'last2FullMonths',  value: 'tm-2m/tm',     label:l10n('Last 2 full months') },
			last3FullMonths:  {id: 'last3FullMonths',  value: 'tm-3m/tm',     label:l10n('Last 3 full months') },
			last6FullMonths:  {id: 'last6FullMonths',  value: 'tm-6m/tm',     label:l10n('Last 6 full months') },
			last12FullMonths: {id: 'last12FullMonths',  value: 'tm-12m/tm',    label:l10n('Last 12 full months') },
			last13FullMonths: {id: 'last13FullMonths',  value: 'tm-13m/tm',    label:l10n('Last 13 full months') },
			thisYear:         {id: 'thisYear',  value: 'ty/ty+1y',     label:l10n('This year') },
			lastFullYear:     {id: 'lastFullYear',  value: 'ty-1y/ty',     label:l10n('Last year') },
		};
	};

	// Helper method to return the presets in a format valid to be used with other components.
	this.getDateRanges = function () {
		const ranges = _.omit(this.getPresets(), 'none');
		return _.map(ranges, function (o) {
			return {
				id:         o.id,
				definition: o.value,
				name:       o.label,
				itemType:   'dateRange',
				template: true,
			};
		});
	};

	this.getDateRangesWithRelevancyScore = function (appService) {
		return appService.get('/usage/dateranges/summary', {
params:{
			expansion:'relevancyScore,count,mostRecentTimestamp',
		},
}).then((result) => {
			const dateRanges = this.getDateRanges();
			dateRanges.forEach((dateRange) => {
				const usageSummary = result.data[dateRange.id];
				if (usageSummary) {
					dateRange.usageSummary = usageSummary;
				}
			});
			return dateRanges;
		});
	};

	this.getDateRangeByValue = function (value) {
		return _.indexBy(this.getPresets(), 'value')[value];
	};

	// Helper method to return the granularities in a format valid to be used with other components.
	this.getGranularities = function () {
		return [
			{
id: 'variables/daterangeminute',  granularityWeight: 4, itemType:  'dimension', type:'time', isTime: true, name: l10n('Minute'),
},
			{
id: 'variables/daterangehour',    granularityWeight: 5, itemType:  'dimension', type:'time', isTime: true, name: l10n('Hour'),
},
			{
id: 'variables/daterangeday',     granularityWeight: 6, itemType:  'dimension', type:'time', isTime: true, name: l10n('Day'),
},
			{
id: 'variables/daterangeweek',    granularityWeight: 7, itemType:  'dimension', type:'time', isTime: true, name: l10n('Week'),
},
			{
id: 'variables/daterangemonth',   granularityWeight: 8, itemType:  'dimension', type:'time', isTime: true, name: l10n('Month'),
},
			{
id: 'variables/daterangequarter', granularityWeight: 9, itemType:  'dimension', type:'time', isTime: true, name: l10n('Quarter'),
},
			{
id: 'variables/daterangeyear',    granularityWeight: 10, itemType: 'dimension', type:'time', isTime: true, name: l10n('Year'),
},
		];
	};

	// For a given list of granularities, find the lowest one.
	this.getLowestGranularity = function (granularities) {
		const granularityiesById = _.indexBy(this.getGranularities(), 'id');
		let lowestGranularity = granularityiesById['variables/daterangeyear'];
		_.each(granularities, function (granularity) {
			const newGranularity = granularityiesById[granularity];
			if (newGranularity && newGranularity.granularityWeight < lowestGranularity.granularityWeight) {
				lowestGranularity = newGranularity;
			}
		});
		return lowestGranularity.id;
	};

	// Sort the granularities by weight, and then grab the next available one if possible.
	this.decreaseGranularity = function (incomingGranularity) {
		let decreasedGranularity = incomingGranularity; // Default to day.
		const granularityiesSortedByWeightDescending = _.sortBy(this.getGranularities(), 'granularityWeight').reverse();
		_.each(granularityiesSortedByWeightDescending, function (granularity, i) {
			if (granularity.id === incomingGranularity && granularityiesSortedByWeightDescending[i + 1]) {
				decreasedGranularity = granularityiesSortedByWeightDescending[i + 1].id;
			}
		});
		return decreasedGranularity;
	};

	this.getAMOptions = function () {
		const amString = moment.langData().meridiem(10, 0, false);
		const pmString = moment.langData().meridiem(15, 0, false);
		return [{value:'am', label:amString}, {value:'pm', label:pmString}];
	};

	this.getSecondOptions = function () {
		return _.map(_.range(0, 60), function (o, k) { return {value: o, label: (o < 10 ? '0' : '') + o }; });
	};

	this.getMinuteOptions = function () {
		return _.map(_.range(0, 60), function (o, k) { return {value: o, label: (o < 10 ? '0' : '') + o }; });
	};

	this.getHourOptions = function () {
		return _.map(_.range(1, 13), function (o, k) { return {value: o, label:o + ''}; });
	};

	this.getMonthOptions = function () {
		return _.map(moment.months(), function (o, k) { return {value: k, label:o + ''}; });
	};

	this.getYearOptions = function () {
		// Range goes to one less than the max. So if your max is 2019, it will really be 2018
		return _.map(_.range(moment().year() - 20, moment().year() + 4), function (o) { return {value: o, label:o}; });
	};

	// From user input, try to see if it is a valuable date.
	this.getReasonableDate = function (value) {
		if (_.trim(value) === '') { return null; }
		const pieces = value.split('-');
		value = moment(value, 'YYYY-MM-DD hh:mm:ss a');
		// If the date is not valid, don't change the calendars. Silently fail. The user may be still typing in the date.
		if (!value.isValid() || pieces.length !== 3 || value.year() < 1900 || value.year() > 2090) {
			return null;
		}
		return value;
	};

	this.getBestGuessFormulaForRange = function (range, referenceDate) {
		const forceDayRange = range.start.isSame(range.end, 'day'); // If the start and end are on the same day, we want to skip all other rangeTypes.
		const startMatches = getRangeBoundaryMatchesForDate(range.start);
		const endMatches = getRangeBoundaryMatchesForDate(range.end, true);

		// Iterate over each of the start matches to see if there is a matching end match with the same rangeType.
		// For example find a match where the start and end both are weekly boundaries.
		// We start at the biggest ranges and work down in granularity
		let result = null;
		_.each(startMatches, function (startMatch) {
			if (forceDayRange && startMatch.rangeType !== 'day') { return; }
			_.each(endMatches, function (endMatch) {
				if (startMatch.rangeType === endMatch.rangeType) {
					result = {startMatch: startMatch, endMatch:endMatch};
					return false;
				}
			});
			if (result) {
				return false;
			}
		});

		// We will always have a result, because "day" will always match.
		return getFormulaForMatch(result.startMatch, referenceDate) + '/' + getFormulaForMatch(result.endMatch, referenceDate);
	};

	this.getBestGuessRangeTypeForRange = function (range) {
		const bestGuessFormulaForRange = this.getBestGuessFormulaForRange(range);
		const rangeParts = bestGuessFormulaForRange.split('/');
		const start = rangeParts[0].slice(0, 2);
		const end = rangeParts[1].slice(0, 2);

		if (['tm', 'th', 'td', 'tw', 'tm', 'tq', 'ty'].indexOf(start) !== -1 && start === end) {
			return rangeTypeByFormulaId[start];
		}
		return 'day';
	};

	this.getGranularityDimensionBasedOnDateRange = function (range) {

		if (_.isString(range)) {
			range = this.getRangeFromRangeString(range);
		}

		const $start = range.start;
		const $end = range.end;

		const numHours = $end.diff($start, 'hours');
		const numDays = $end.diff($start, 'days');
		if (numHours < 3) {
			return 'variables/daterangeminute';
		} else if (numDays < 6) {
			return 'variables/daterangehour';
		} else if (numDays < 93) {
			return 'variables/daterangeday';
		} else if (numDays < 367) {
			return 'variables/daterangeweek';
		} else if (numDays < 733) {
			return 'variables/daterangemonth';
		} else {
			return 'variables/daterangequarter';
		}
	};

	this.getDateFromUTC = function (date) {
		return moment(date).utc().add(reportSuite.currentTimezoneOffset, 'hours');
	};


	// For a given date and rangeType, find the correct formula in respect to the referenceDate.
	function getFormulaForMatch(match, referenceDate) {
		const currentDay = moment(referenceDate || that.now()); // we allow a reference date to override today for testing purposes.
		const targetDay = match.date;
		const rangeType = match.rangeType;
		let quantity = 0;

		const range = that.currentRange(currentDay, rangeType);

		if (targetDay.isSame(range[match.boundaryPosition], 'day')) { // If it is the same day, then we have a match. Get the formula.
			return getFormulaBasedOffBoundary(match.boundaryPosition, rangeType, quantity);
		}

		let pointer = range[match.boundaryPosition].clone();
		// Move the pointer to the next/previous range until it is passes the target day. Keep track of the quantity.
		if (pointer.isAfter(targetDay, 'day')) {
			while (pointer.isAfter(targetDay, 'day')) {
				pointer = that.previousRange(pointer, rangeType)[match.boundaryPosition];
				quantity--;
			}
		} else {
			while (pointer.isBefore(targetDay, 'day')) {
				pointer = that.nextRange(pointer, rangeType)[match.boundaryPosition];
				quantity++;
			}
		}

		// Now that we have the quantity, get the formula.
		return getFormulaBasedOffBoundary(match.boundaryPosition, rangeType, quantity);
	}

	// Returns a formula for a given rangeType and quantity.
	function getFormulaBasedOffBoundary(boundaryType, rangeType, quantity) {
		if (boundaryType === 'end') {
			quantity += 1;
		}
		if (quantity === 0) {
			quantity = '';
		} else if (quantity > 0) {
			quantity = '+' + quantity + rangeType[0];
		} else {
			quantity = '-' + Math.abs(quantity) + rangeType[0];
		}

		return 't' + rangeType[0] + quantity; // this returns ty or ty+1y (for the end)
	}

	// Iterate over all the range types (year, quarter, month...) and see if the date lands on either the start or end of the boundary.
	function getRangeBoundaryMatchesForDate(date, isEnd) {
		const matches = [];
		// Find all the ranges that either start or end on the desired day.
		_.each(formulaMapping, function (rangeType) {
			const range = that.currentRange(date, rangeType);
			if (date.isSame(range.start, 'day') && !(rangeType === 'day' && isEnd)) { // For the "day" range, provide
				matches.push({date: range.start, boundaryPosition: 'start', rangeType: rangeType});
			}
			if (date.isSame(range.end, 'day')) {
				matches.push({date: range.end, boundaryPosition: 'end', rangeType: rangeType});
			}
		});
		return matches;
	}

	this.getRollingString = function (formula) {
		formula = formula.split('/');
		let start = formula[0];
		let end = formula[1];
		if (this.isFormulaFixedDate(start)) {
			start = l10n('fixed start');
		} else {
			start = rollingStringLabels[start.slice(0, 2)];
		}
		if (this.isFormulaFixedDate(end)) {
			end = l10n('fixed end');
		} else {
			end = rollingStringLabels[end.slice(0, 2)];
		}
		return '(' + start + ' - ' + end + ')';
	};

	// This creates an array with arrays that are weeks, that each contain 7 days.
	function addDayToWeeks(day, weeks) {
		if (_.last(weeks).length >= 7) {
			weeks.push([]);
		}
		_.last(weeks).push(day);
	}

	function getNumberOfBlankDaysAtBeginningOfCalendar(day) {
		let weekday = moment(day).isoWeekday();            // get the day of week it is normally
		weekday = weekday === 7 ? 0 : weekday;              // make sunday be day 0 instead of 7.
		let blanksDays = weekday - that.getFirstWeekDay(); // for example: if Jan 1st starts on wed(3), and custom calendar has week start on wed, we have 0 blanks.
		if (blanksDays < 0) {
			blanksDays += 7; // if we are under 0, add seven to be within a normal week number.
		}
		return blanksDays;
	}

	this.getCalendarDetails = function (range, opts = {}) {

		let selectionRange = opts.range;
		const dateType = opts.dateType;


		function isDisabled(day) {
			let disabled = false;
			if (opts.min && day.isBefore(opts.min, 'day')) { disabled = true; }
			if (opts.max && day.isAfter(opts.max, 'day')) { disabled = true; }
			return disabled;
		}

		// Gets the current range for the current date type, start or end.
		if (opts.viewDate) {
			range = this.currentRange(opts.viewDate, 'month');
		}
		else if (selectionRange) {
			range = this.currentRange(selectionRange[dateType], 'month');
		}

		const days = this.getDaysAsArray(range);

		const startOfRange = days[0];
		const endOfRange = _.last(days);

		const dayLabels = moment.weekdaysMin();
		for (let i = this.getFirstWeekDay(); i > 0; i--) {
			dayLabels.push(dayLabels.shift()); // Shift the day labels around to match the designated first day of week.
		}

		let start;
		let end;
		let viewDate;

		const weeks = [[]];
		const blankDays = getNumberOfBlankDaysAtBeginningOfCalendar(days[0]);
		_.each(_.range(0, blankDays), function (day) { addDayToWeeks({isBlankDay: true}, weeks); }); // Add all the blank days, then all the real days.
		_.each(days, function (day, k) {

			if (opts.viewDate && day.isSame(opts.viewDate, 'day')) {
				viewDate = day;
			}

			if (selectionRange) {
				day.__isStart = !!((selectionRange.start && day.isSame(selectionRange.start, 'day')));
				day.__isEnd = !!((selectionRange.end && day.isSame(selectionRange.end, 'day')));

				day.__highlighted = false;

				if (dateType === 'start' && selectionRange.start && selectionRange.end && day.isAfter(selectionRange.start, 'day') && (day.isBefore(selectionRange.end, 'day') || day.isSame(selectionRange.end, 'day'))) {
					day.__highlighted = true;
				}

				if (dateType === 'end' && selectionRange.start && selectionRange.end && (day.isSame(selectionRange.start, 'day') || day.isAfter(selectionRange.start, 'day')) && day.isBefore(selectionRange.end, 'day')) {
					day.__highlighted = true;
				}

				day.__selected = day.isSame(selectionRange[dateType], 'day');

				if (dateType === 'start'  &&  day.__selected) {
					start = day;
				}

				if (dateType === 'end'  &&  day.__selected) {
					end = day;
				}
			}


			day.__isToday = day.isSame(that.now(), 'day');
			day.__disabled = isDisabled(day);
			day.__positionInMonth = k;

			addDayToWeeks(day, weeks);
		});

		viewDate = viewDate || (dateType === 'start' ? start : end);

		if (!selectionRange) {
			selectionRange = {};
		}

		return {
			monthOptions: this.getMonthOptions(),
			yearOptions: this.getYearOptions(),
			months: this.getMonthOptions(),
			weeks: weeks,
			selectedMonth: days[15].month(),
			selectedYear: days[15].year(),   //TODO: If you go past 2016, it won't show correctly. What min and maxes should we have for years?
			dayLabels: dayLabels,
			middleOfMonthDay: days[15],
			currentDay: this.now(),
			start: start,
			end: end,
			startOfRange: startOfRange,
			endOfRange: endOfRange,
			viewDate: viewDate,

			subtractMonth:function () {
				selectionRange.viewDate = that.getPositionInRange(that.previousRange(days[15], 'month'), this.viewDate.__positionInMonth);
				return selectionRange;
			},

			addMonth:function () {
				selectionRange.viewDate  = that.getPositionInRange(that.nextRange(days[15], 'month'), this.viewDate.__positionInMonth);
				return selectionRange;
			},

			changeMonth: function (newMonth) {
				const newDay = days[15].clone(); // take the day from the middle of the month, and change it to be the new month.
				newDay.month(+newMonth);
				selectionRange.viewDate = that.getPositionInRange(that.currentRange(newDay, 'month'), this.viewDate.__positionInMonth);
				return selectionRange;
			},

			changeYear: function (newYear) {
				const newDay = days[15].clone(); // take the day from the middle of the month, and change it to be the new month.
				newDay.year(+newYear);
				selectionRange.viewDate = that.getPositionInRange(that.currentRange(newDay, 'month'), this.viewDate.__positionInMonth);
				return selectionRange;
			},

			selectMonth: function () {
				return that.currentRange(days[15], 'month');
			},

			selectYear: function () {
				return that.currentRange(days[15], 'year');
			},
		};
	};

	// Gets a desired position in a range. Otherwise, gets the last day.
	this.getPositionInRange = function (range, position) {
		const days = this.getDaysAsArray(range);
		return days[Math.min(days.length - 1, position)];
	};

	this.rangeContains = function (range, date, granularity = 'day') {
		return moment(range.start).startOf(granularity) <=  moment(date).startOf(granularity) && moment(range.end).startOf(granularity) >=  moment(date).startOf(granularity);
	};


	this.now = function () {
		// globalReferenceDate is always set for PDF version, and not for the web version.
		// globalReferenceDate is always in UTC format, so it needs to be modified with the timezone offset.
		// The globalReferenceDate will be in utc time in the format "2016-08-11T22:00:30Z".
		// If a globalReferenceDate is not set, such as for the web version, then it will default to the current time.
		// Either way, it is converted to utc, and then modified by the timezone for both web and pdf versions.
		return moment(moment(globalReferenceDate).utc().subtract(reportSuite.uiTimezoneOffset, 'minutes').format(rangeStringFormat), rangeStringFormat);
	};

	// Helper function for ad-calendar and ad-calendarInput to update/set the stringValue.
	// If newValue is set, it will set the stringValue. Otherwise, it will update the string value to reflect the values in param.values.
	this.setParamsValueFromStringValue = function (params) {
		if (params.stringValue === undefined) {
			return;
		}
		const dateRangeId = params.dateRangeId;
		params.value = that.getRangeFromRangeString(params.stringValue);
		params.value.dateRangeId = dateRangeId; // this assumes that the string value matches the daterangeid that is stored.
		params.useRollingDates = that.isRangeStringAFormula(params.value.formula);
	};

	this.updateStringValue = function (params) {
		params.stringValue = that.getRangeStringFromRange(params.value);
	};

	this.getGranularityFromGranularityId = function (granularityId) {
		return getGranularityFromItemId(granularityId);
	};

	this.getGranularityIdFromGranularity = function (granularity) {
		return getGranularityIdFromItem(granularity);
	};

	// When crossing a DST difference, we don't want our hours to be off by an hour. So, we adjust it by adding back or removing the extra hour if it spans DST.
	this.getTimeDiff = function (start, end, granularityId, roundToStartOfPeriod = false) {
		let duration = moment.duration(end.diff(start));
		let dstOffset = 0;

		if (start.isDST() && !end.isDST()) {
			dstOffset = -1;
		}

		if (!start.isDST() && end.isDST()) {
			dstOffset = 1;
		}

		if (roundToStartOfPeriod) {
			const roundedStart = this.currentRange(start, this.getGranularityFromGranularityId(granularityId)).start;
			const roundedEnd = this.currentRange(end, this.getGranularityFromGranularityId(granularityId)).start;
			duration = moment.duration(roundedEnd.diff(roundedStart));
		}

		switch (granularityId) {
			case SECOND:
				duration = duration.asSeconds() + (dstOffset * 3600);
				break;
			case MINUTE:
				duration = duration.asMinutes() + (dstOffset * 60);
				break;
			case HOUR:
				duration = duration.asHours() + dstOffset;
				break;
			case DAY:
				duration = duration.asDays();
				break;
			case WEEK:
				duration = duration.asWeeks();
				break;
			case MONTH:
				duration = duration.asMonths();
				break;
			case QUARTER:
				duration = duration.asMonths() / 3;
				break;
			case YEAR:
				duration = duration.asYears();
				break;
		}

		return Math.round(duration);
	};

	this.getNumDimensionItemsBetweenDates = function (start, end, granularityId) {
		const granularity = this.getGranularityFromGranularityId(granularityId);

		// Subtract one just to make sure we can iterate at least once.
		let numItems = this.getTimeDiff(start, end, granularityId) - 1;
		end = this.currentRange(end, granularity, true).end;

		// Get the adjusted current range.
		const range = this.currentRange(start, granularity);
		const result = this.add(range.start, numItems, granularity);
		let currentRange = this.currentRange(result, granularity);

		// now just loop through the ranges until we have the appropriate number of items.
		while (currentRange.end <= end) {
			numItems++;
			currentRange = this.nextRange(currentRange.end, granularity);
		}

		return numItems;
	};


	this.deprecatedGetPageDateRange = function () {
		if (window.page_controller &&
			window.page_controller.models &&
			window.page_controller.models.date_range_model) {

			let dateRangeModel = window.page_controller.models.date_range_model;
			let startDate;
			let endDate;

			startDate = this.parseYYYMMDDHH(dateRangeModel.getStartDate().toYMD());
			endDate   = this.parseYYYMMDDHH(dateRangeModel.getEndDate().toYMD());

			//before this line, endDate is midnight on the last day of the date range. Extend the end date one day so that we include the full last day in the date range.
			endDate.add('days', 1);

			return {
				start: startDate.startOf('day'),
				end: endDate.startOf('day'),
			};
		}
		return null;
	};

	/* Get the date range as a string */
	this.deprecatedGetPageDateRangeString = function () {
		const range = this.deprecatedGetPageDateRange();
		if (range) {
			// The backend no longer accepts ISO strings, it will throw an exception if we provide a 'Z' in the time range
			// So use StandardFormat from the date service instead.
			const formattedStart = this.getStandardFormat(range.start);
			const formattedEnd = this.getStandardFormat(range.end);
			return formattedStart + '/' + formattedEnd;
		}
		return null;
	};

	this.isCustomCalendar = function () {
		return _.get(reportSuite, 'calendarType.type', 'GREGORIAN') !== 'GREGORIAN';
	};

}

DateService.setReferenceDate = function (date) {
	globalReferenceDate = date;
};

let appReportSuite;

// All calls to the date service should be of the form: DateService.instance().someCommand()
const DateServiceSingleton = {
	_instances: {},

	// For most use cases, no paramaters need to be passed to instance or setInstance.
	instance: function (reportSuite) {
		reportSuite = reportSuite || appReportSuite;

		if (!this._instances[reportSuite.rsid]) {  // If a dateservice has not been initialized yet, then initialize it.
			this.setInstance(reportSuite);
		}

		return this._instances[reportSuite.rsid];
	},

	setInstance: function (reportSuite) {
		this._instances[reportSuite.rsid] = new DateService(reportSuite);
		return this._instances[reportSuite.rsid];
	},

	setAppInstance: function (reportSuite) {
		appReportSuite = reportSuite;
		return this.setInstance(reportSuite);
	},

	parseYYYMMDDHH: function (str) {
		return parseYYYMMDDHH(str);
	},

	formatYYYMMDDHH: function (str, dimensionId) {
		return formatYYYMMDDHH(str, dimensionId);
	},

	getGranularityList: function () {
		return granularityList;
	},

	setReferenceDate: function (date) {
		DateService.setReferenceDate(date);
	},

	getGranularityFromItemId: getGranularityFromItemId,

	getGranularityIdFromItem: getGranularityIdFromItem,
};

DateServiceSingleton.isTimeDimension = function (dimensionId) {
	return _.contains(
[
		HOUR,
		DAY,
		WEEK,
		MONTH,
		QUARTER,
		YEAR,
	],
	dimensionId,
);
};

DateServiceSingleton.HOUR = HOUR;
DateServiceSingleton.DAY = DAY;
DateServiceSingleton.WEEK = WEEK;
DateServiceSingleton.MONTH = MONTH;
DateServiceSingleton.QUARTER = QUARTER;
DateServiceSingleton.YEAR = YEAR;

DateServiceSingleton.availableGranularitiesForAnomalyDetection = [HOUR, DAY, WEEK, MONTH];

export default DateServiceSingleton;
