//https://stackoverflow.com/questions/48681474/escape-charaters-are-not-interpreted-while-reading-text-from-file-in-node-js
module.exports = function unbackslash(s) {
    return s.replace(/\\([\\rnt'"])/g, function (match, p1) {
        if (p1 === 'n') return '\n';
        if (p1 === 'r') return '\r';
        if (p1 === 't') return '\t';
        if (p1 === '\\') return '\\';
        return p1;       // unrecognised escape
    });
};

