/*eslint no-shadow:0*/
/* @flow */
import { EventEmitter, Model } from '@analytics/essence';
import Entity from './Entity';
import _ from './_';


/*::
import Entity from './Entity';
import Model from './Model';
import EntityRepository from './EntityRepository';
import Http from './Http';
import EntityCache from './EntityCache';
*/

/*::
	type Options = {
		filterByIds?: Array<Class<Entity>>; // A list of types that support filterByIds
	};
*/

export default class EntityRepositoryFacade extends EventEmitter {

	/*::
		_http: Http;
		_cache: EntityCache;
		_options: Options;
		_repos: Map;
	*/

	constructor(http /*: Http */, cache /*: EntityCache */, options /*: Options */ = {}) {
		super();
		this._http = http;
		this._cache = cache;
		this._options = Object.assign({
			getWithIdsBatchSize: 100,
		}, options);
		this._repos = new Map();
	}

	get(entityType /*: Class<T> */, id /*: string */, config /*: Object */ = {})  /*: Promise<T> */ {
		return this._getRepository(entityType).get(entityType, id, config).then(entity => this._hydrateIfNecessary(entity, config));
	}

	reload(entity /*: T */, config /*: Object */ = {}) /*: Promise */ {
		return this.get(entity.constructor, entity.id, config).then((e) => { entity.update(e, { propertiesToUpdate: config.params.expansion.split(',') }); return entity; });
	}

	reloadAll(entities /*: <Array<T>> */, config /*: Object */ = {}) /*: Promise<Array<T>> */ {
		return Promise.all(entities.map(entity => this.reload(entity, config)));
	}

	query(entityType /*: Class<T> */, config /*: Object */ = {}) /*: Promise<Array<T>> */ {
		return this._getRepository(entityType).query(entityType, config);
	}

	// When providing a custom limit for paging, use this endpoint
	// to get back just a page of data (and the paging envelope)
	pagedQuery(entityType /*: Class<T> */, config /*: Object */ = {}) /*: Promise<PagedResponse<T>> */ {
		return this._getRepository(entityType).pagedQuery(entityType, config);
	}

	count(entityType /*: Class<T> */, config /*: Object */ = {}) /*: Promise<Array<T>> */ {
		return this._getRepository(entityType).count(entityType, config);
	}

	save(entity /*: T */, config /*: Object */ = {}) /*: Promise */ {
		return this._getRepository(entity.constructor).save(entity, config);
	}

	delete(entity /*: T */, config /*: Object */ = {}) /*: Promise */ {
		return this._getRepository(entity.constructor).delete(entity, config);
	}

	activate(entity /*: T */, config /*: Object */ = {}) /*: Promise */ {
		return this._getRepository(entity.constructor).activate(entity, config);
	}

	pause(entity /*: T */, config /*: Object */ = {}) /*: Promise */ {
		return this._getRepository(entity.constructor).pause(entity, config);
	}

	getWithIds(entityType /*: Class<T> */, ids /*: Array<string> */, namesById /*: Object */) /*: Promise<Array<T>> */ {
		return this._getRepository(entityType).getWithIds(entityType, ids, namesById);
	}

	hydrate/*::<T: Model>*/(model/*: T*/, options = {})/*: Promise<T> */ {

		const doHydration = () => {
			const entities = model.getEmbeddedModels(Entity);
			return this._loadUnCachedEntities(entities).then(() => {
				this.populateEmbeddedEntities(model, entities, options);
				return model;
			});
		};

		if (options.waitForCache) {
			return this._cache.when(...options.waitForCache)
				.then(doHydration);
		} else {
			return doHydration();
		}

	}

	// Use hydrateSync() when you are sure the entities you need to hydrate were previously cached.
	// If ignoreIfMissing is false, this method will throw an error if it can't find an entity in the cache, otherwise it will skip missing entities.
	// If you want to automatically make the request to load missing entities, use hydrate() instead.
	hydrateSync/*::<T: Model>*/(model/*: T*/, options = {})/*: <T: Model> */ {
		options = Object.assign({
			ignoreIfMissing: false,
		}, options);

		const entities = model.getEmbeddedModels(Entity);
		this.populateEmbeddedEntities(model, entities, options);
		return model;
	}

	populateEmbeddedEntities(modelToPopulate, entities, options = {}) {
		options = Object.assign({
			ignoreIfMissing: false, //pass true to ignore embedded entities that aren't in the cache
			onIgnore:() => {},
		}, options);

		const shouldIgnore = entity => options.ignoreIfMissing && !this._cache.has(entity.constructor, entity.id);
		const providerEntities = new Set();
		entities.forEach((entity) => {
			if (!entity.id || shouldIgnore(entity)) {
				options.onIgnore(entity);
			} else {
				providerEntities.add(this._cache.get(entity.constructor, entity.id));
			}
		});

		const visit = (model, context) => {
			if (model instanceof Entity && model.id && model !== modelToPopulate && !shouldIgnore(model)) {
				const providerEntity = this._cache.get(model.constructor, model.id);
				if (_.isArray(context.parent[context.property])) {
					context.parent[context.property].replace(model, providerEntity);

					if (context.parent.onHydrate) {
						context.parent.onHydrate({
							property: context.property,
							value: providerEntity
						});
					}
				} else if (context.parent[context.property] instanceof Model) {
					context.parent[context.property] = providerEntity;

					if (context.parent.onHydrate) {
						context.parent.onHydrate({
							property: context.property,
							value: providerEntity
						});
					}
				}
			}
		};
		modelToPopulate.visit(visit, {visited: providerEntities} /* We want to skip over entities that have already been replaced */);
	}

	async _loadUnCachedEntities(entities /*: Array<Entity> */) /*: Promise */ {
		//If an entity does not needsHydration() and it is not in the cache, add it to the cache. It will be skipped over in the next step.
		entities.forEach((entity) => {
			if (!entity.constructor.needsHydration(entity) && !this._cache.has(entity.constructor, entity.id)) {
				this._cache.add(entity);
			}
		});

		const missingEntities = entities.filter(entity => entity.id && !this._cache.has(entity.constructor, entity.id)); // AdHoc entities, such as some DateRanges, will not have an id. We don't need to look them up.

		if (missingEntities.length === 0) {
			return;
		}
		return this.loadEntities(missingEntities);
	}

	async loadEntities(entities) {
		const promises = [];
		const groups = this._groupByType(entities);
		groups.forEach((entities, type) => {
			const entitiesWithIds = entities.filter(e => e.id);
			const uniqueEntities = _.uniq(entitiesWithIds, 'id');
			const ids = uniqueEntities.map(e => e.id);
			const namesById = _.zipObject(ids, uniqueEntities.map(e => e.name));
			promises.push(this.batchGetWithIds(type, ids, namesById));
		});
		const loadedEntities = _.flatten(await Promise.all(promises));
		loadedEntities.forEach(entity => entity.id && this._cache.add(entity));
		return loadedEntities;
	}

	async batchGetWithIds(type, ids, namesById) {
		let promises = [];
		let idBatches = _.chunk(ids, this._options.getWithIdsBatchSize);
		idBatches.forEach((idsBatch) => {
			let result = this.getWithIds(type, idsBatch, namesById);
			promises.push(result);
		});
		const batches = await Promise.all(promises);
		return _.flatten(batches);
	}

	_groupByType(entities /*: Array<Entity> */) /*: Map<Class<Entity>, Array<Entity>> */ {
		const group = new Map();
		entities.forEach((entity) => {
			if (!group.has(entity.constructor)) {
				group.set(entity.constructor, []);
			}
			// $FlowIgnore
			group.get(entity.constructor).push(entity);
		});
		return group;
	}

	_getRepository(entityType /*: Class<T> */) /*: EntityRepository */ {
		const Repo = entityType.repository();
		let repoInstance = this._repos.get(Repo);
		if (!repoInstance) {
			repoInstance = new Repo(this._http, this._cache, this._options);
			this._repos.set(Repo, repoInstance);
		}
		return repoInstance;
	}

	_hydrateIfNecessary(entity, config) {
		if (config.hydrate !== false) {
			const options = config.waitForCache ? {waitForCache: config.waitForCache} : {};
			// $FlowIgnore
			return this.hydrate(entity, options);
		} else {
			// $FlowIgnore
			return entity;
		}
	}

}
