import _ from './_';
import moment from './moment';


/*
	All the calendars extend the Gregorian.
	They possibly need to implement the following: getFirstWeekday, getCurrentMonth, getCurrentQuarter, getCurrentYear
	Since all the methods use moment, the date can be anything moment parses, such as date object, moment object, or {year:2015, month:0, day: 1}
	IMPORTANT TIP: You can figure out the date ranges for any QRS/NRF calendar if you know the anchor date. The anchor date will always
					be in the first week of every year. It dictates if years need 53 or 52 weeks. If for some reason it will be in the
					second week of a year, then an extra week is added to the previous year so that it will be in the first week. With
					this knowledge, you can look at any calendar and know all the ranges.
	IMPORTANT TIP: Modified Gregorian can only have the day of week, and starting month be changed. But a month is still the same as a regular gregorian month.
*/

// This is the standard calendar.
class Gregorian {
	constructor() {
		this.name = 'Gregorian';
	}

	getFirstWeekday() {
		return 0; // First day is Sunday
	}

	// This is used by everything other than the Gregorian calendar.
	_getFirstWeekdayUsingAnchorDate() {
		return this.anchorDate.isoWeekday() === 7 ? 0 : this.anchorDate.isoWeekday();
	}

	// Gets the range of the current minute. Works for all calendar types.
	getCurrentMinute(date) {
		const d = moment(date);
		return {start: d.startOf('minute'), end: d.clone().endOf('minute')};
	}

	// Gets the range of the current hour. Works for all calendar types.
	getCurrentHour(date) {
		const d = moment(date);
		return {start: d.startOf('hour'), end: d.clone().endOf('hour')};
	}

	// Gets the range of the current day. Works for all calendar types.
	getCurrentDay(date) {
		const d = moment(date);
		return {start: d.startOf('day'), end: d.clone().endOf('day')};
	}

	// Works for all calendar types.
	getCurrentWeek(date) {
		const d = moment(date);
		let offset = d.day() - this.getFirstWeekday(); //find how many days past the starting week day the anchor is now.
		offset = offset < 0 ? offset + 7 : offset; // roll over to next week if it already past it.
		return {start: d.subtract(offset, 'days'), end: d.clone().add(6, 'days').endOf('day')};// this will choose the day in the current week. Don't use week because of custom calendars.
	}

	getCurrentMonth(date) {
		const d = moment(date);
		return {start: d.clone().startOf('month'), end: d.clone().endOf('month')};
	}

	getCurrentQuarter(date) {
		const d = moment(date);
		return {start: d.clone().startOf('quarter'), end: d.clone().endOf('quarter')};
	}

	getCurrentYear(date) {
		const d = moment(date);
		return {start: d.clone().startOf('year'), end: d.clone().endOf('year')};
	}
}

class ModifiedGregorian extends Gregorian {
	constructor(anchorDate) {
		super();
		this.name = 'ModifiedGregorian';
		this.anchorDate = moment(anchorDate);
		this.monthOffset = this.anchorDate.month();
	}

	getCurrentQuarter(date) {
		const d = moment(date);
		let offset = d.month() - this.monthOffset;
		offset = offset >= 0 ? offset : offset + 12; // find out where the month is when everything is reindexed according to the custom starting month.
		offset %= 3; //This finds out how many months ahead of a 3 month breakboint the current month is.
		const start = d.clone().startOf('month').subtract(offset, 'months');
		return {start: start, end: start.clone().add(2, 'months').endOf('month')}; // the end is always 2 months after the starting month.
	}

	getCurrentYear(date) {
		const d = moment(date);
		const range = {start: d.clone().startOf('year').add(this.monthOffset, 'months'), end: d.clone().endOf('year').add(this.monthOffset, 'months').endOf('month')};
		if (range.start.isAfter(d, 'day')) { // if the date occurs before the anchor month, it is actually the previous year.
			range.start.subtract(1, 'years');
			range.end.subtract(1, 'years').endOf('month');
		}
		return range;
	}
}

ModifiedGregorian.prototype.getFirstWeekday = Gregorian.prototype._getFirstWeekdayUsingAnchorDate;


class NRF extends Gregorian {
	constructor(anchorDate) {
		super();
		this.name = 'NRF';
		anchorDate = anchorDate || {year:2001, month:1, day:4};
		this.anchorDate = moment(anchorDate);
	}

	getWeekPattern(isBigYear) {
		return isBigYear ? [4, 5, 4, 4, 5, 4, 4, 5, 4, 4, 5, 5] : [4, 5, 4, 4, 5, 4, 4, 5, 4, 4, 5, 4];
	}

	getCurrentMonth(date) {
		const d = moment(date);
		const yearRange = this.getCurrentYear(date);
		const startOfYear = yearRange.start;
		const months = this.getWeekPattern(yearRange.isBigYear);

		let range;
		const pointer = moment(startOfYear).clone(); // Iterate over each month until we hit the current month.
		_.each(months, function (o, k) {
			const end = pointer.clone().add(o, 'weeks');
			if (d.isBefore(end, 'day')) {
				range = {
start: pointer, end: end.add(-1, 'days'), monthIndex: k, isBigYear: yearRange.isBigYear,
}; // Month index is useful for finding out what quarter we are in.
				return false;
			}
			pointer.add(o, 'weeks');
		});
		range.end.endOf('day');
		return range;
	}

	getCurrentQuarter(date) {
		const monthRange = this.getCurrentMonth(date);
		const months = this.getWeekPattern(monthRange.isBigYear);
		const monthIndexForStartOfQuarter = Math.floor(monthRange.monthIndex  / 3) * 3; // find the quarter it is in, then multiply by 3 months in each quarter.
		const monthIndexForEndOfQuarter = monthIndexForStartOfQuarter + 2; // we only add 2 instead of 3 since we are inclusive.
		const start = monthRange.start;
		const end = monthRange.end;
		let i;
		for (i = monthRange.monthIndex; i > monthIndexForStartOfQuarter; i--) {
			start.subtract(months[i - 1], 'weeks');
		}
		for (i = monthRange.monthIndex; i < monthIndexForEndOfQuarter; i++) {
			end.add(months[i + 1], 'weeks');
		}
		return {start: start, end:end.endOf('day')};
	}

	getCurrentYear(date) {
		const d = moment(date);
		let currentAnchor = this.anchorDate.clone().year(d.year() + 1); // The anchor always has to be in the first week of the year. There is a possibility it is in one year future, (date is before but same week as anchor, and anchor is next year.), or it can be same year, or previous year.
		currentAnchor = d.isBefore(this.getCurrentWeek(currentAnchor).start, 'day') ? currentAnchor.add(-1, 'years') : currentAnchor; // ie. anchor is feb1 and date is jan 10
		currentAnchor = d.isBefore(this.getCurrentWeek(currentAnchor).start, 'day') ? currentAnchor.add(-1, 'years') : currentAnchor;
		const startOfYear = this.getCurrentWeek(currentAnchor).start;
		const isBigYear = !startOfYear.clone().add(53, 'weeks').isAfter(currentAnchor.clone().add(1, 'years'));
		return {start: startOfYear.clone(), end: startOfYear.clone().add(isBigYear ? 53 : 52, 'weeks').add(-1, 'days').endOf('day'), isBigYear: isBigYear};
	}
}

NRF.prototype.getFirstWeekday = Gregorian.prototype._getFirstWeekdayUsingAnchorDate;

class QRS extends NRF {
	constructor(anchorDate) {
		super();
		this.name = 'QRS';
		anchorDate = anchorDate || {year:2006, month:0, day:1};
		this.anchorDate = moment(anchorDate);
	}

	getWeekPattern(isBigYear) {
		return isBigYear ? [4, 4, 5, 4, 4, 5, 4, 4, 5, 4, 4, 6] : [4, 4, 5, 4, 4, 5, 4, 4, 5, 4, 4, 5];
	}
}

//https://vendor.jcpenney.com/wps/portal/!ut/p/b1/04_SjzQ0MDY0NTUytjDQj9CPykssy0xPLMnMz0vMAfGjzOKdLEK8jb18DY2cfM3MDTyDLc29nF2MDII9TYAKIoEKDHAARwNC-v088nNT9XOjciwA5feoBw!!/dl4/d5/L2dBISEvZ0FBIS9nQSEh/
class CUSTOM_544 extends NRF {
	constructor(anchorDate) {
		super();
		this.name = 'CUSTOM_544';
		anchorDate = anchorDate || {year:2001, month:1, day:4};
		this.anchorDate = moment(anchorDate);
	}

	getWeekPattern(isBigYear) {
		return isBigYear ? [5, 4, 4, 5, 4, 4, 5, 4, 4, 5, 4, 5] : [5, 4, 4, 5, 4, 4, 5, 4, 4, 5, 4, 4];
	}
}

export default function OmniDateService(type, anchorDate) {
	let calendar;

	// This is temporary support for forcing the calendar to be CUSTOM_544.
	if (typeof window !== 'undefined' && window.location.href.indexOf('loki[custom544]=1') !== -1) {
		return new CUSTOM_544();
	}

	switch (type) {
		case 'MODIFIED_GREGORIAN': calendar = new ModifiedGregorian(anchorDate); break;
		case 'CUSTOM_445': calendar = new QRS(anchorDate); break;
		case 'CUSTOM_454': calendar = new NRF(anchorDate); break;
		case 'CUSTOM_544': calendar = new CUSTOM_544(anchorDate); break;
		case 'QRS': calendar = new QRS(); break;
		case 'NRF': calendar = new NRF(); break;
		default: calendar = new Gregorian(); break;
	}
	return calendar;
}
