/*eslint no-console:0,no-shadow:0*/
import _ from './_';
/*
  Usage:

    function() {
      let someFuncTimer = new PerfTimer('someFuncTimer');
      someFuncTimer.startLap();//use 'laps' so we can calculate average and total execution time

      // execute code ...

      someFuncTimer.endLap();
    }


  To pring out the results when you are done running the function:

    PerfTimer.printAllStats();

  or in the console

    analyticsui.core.PerfTimer.printAllStats();
*/

export default class PerfTimer {
  constructor(timerName) {
    this.timerName = timerName;

    //store everything at the class level so we can print all stats for all timers
    PerfTimer[timerName + 'Timer'] = this;

    this.count   = this.count   || 0;
    this.total   = this.total   || 0;
    this.average = this.average || 0;

    PerfTimer.perfTimers = PerfTimer.perfTimers || [];
    if (!PerfTimer.perfTimers.find(t => t.timerName === timerName)) {
      PerfTimer.perfTimers.push(this);
    }
  }

  getCurrentTime() {
    return performance.now();
  }
 /**
   * TODO: Should we isolate each lap so we don't conflict with other laps of the same timer?
   */
  startLap() {
    PerfTimer[this.timerName + 'LapStartTime'] = this.getCurrentTime();
  }

  endLap() {
    const endTime = this.getCurrentTime();
    const elapsedTime = endTime - PerfTimer[this.timerName + 'LapStartTime'];
    this.count++;
    this.total += elapsedTime;
    this.average = this.total / this.count;
  }

  static clearTimer(name) {
    const timer = PerfTimer.perfTimers.find(timer => timer.timerName === name);

    if (timer) {
      PerfTimer[timer.timerName + 'LapStartTime'] = 0;
      timer.count = 0;
      timer.total = 0;
      timer.average = 0;
    }
  }

  set average(avg) {
    PerfTimer[this.timerName + 'AverageTime'] = avg;
  }

  get average() {
    return PerfTimer[this.timerName + 'AverageTime'];
  }

  set total(total) {
    PerfTimer[this.timerName + 'TotalTime'] = total;
  }

  get total() {
    return PerfTimer[this.timerName + 'TotalTime'];
  }

  set count(count) {
    PerfTimer[this.timerName + 'Count'] = count;
  }

  get count() {
    return PerfTimer[this.timerName + 'Count'];
  }

  printStats() {
    console.log(this.timerName + 'Avg: ', this.average + 'ms');
    console.log(this.timerName + 'Total: ', this.total + 'ms');
    console.log(this.timerName + 'Count: ', this.count);
  }

  static printAllStats() {
    console.log('PRINTING ALL STATS');

    const consoleTableObj = {};
    _.get(PerfTimer, 'perfTimers', []).forEach((timer) => {

      consoleTableObj[timer.timerName] = {
        count           : timer.count,
        'average (in ms)' : _.round(timer.average, 3),
        'total (in ms)'   : _.round(timer.total, 3),
      };

    });

    console.table(consoleTableObj);

  }

  static clearAllTimers() {
    let timer = PerfTimer.perfTimers.forEach((timer) => {
      PerfTimer[timer.timerName + 'LapStartTime'] = 0;
      timer.count = 0;
      timer.total = 0;
      timer.average = 0;

    });
    console.log('All timers cleared');
  }
}
