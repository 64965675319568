export default class BooleanArrays {

	static or(arrays) {
		return this.combine(arrays, (a, b) => a || b);
	}

	static and(arrays) {
		return this.combine(arrays, (a, b) => a && b);
	}

	static combine(arrays, cb) {
		if (!arrays || !arrays.length > 0) {
			return undefined;
		}
		this.validateSameLength(arrays);

		let combined;

		arrays.forEach((currentArray) => {
			if (!combined) {
				combined = currentArray.concat();
			}
			else {
				// Combine values in our combined list with the current list
				for (let i = 0; i < combined.length; i++) {
					combined[i] = cb(combined[i], currentArray[i]);
				}
			}
		});

		return combined;
	}

	static validateSameLength(arrays) {
		const firstLength = arrays[0].length;
		arrays.forEach((array) => {
			if (array.length !== firstLength) {
				throw new Error('All arrays must have the same length.');
			}
		});
	}
}
