export default function createTempGlobalVar(val) {

   if (typeof window !== 'undefined') {

      let i = 1;
      let key = 'temp' + i;
      while (typeof window[key] !== 'undefined') {
         i++;
         key = 'temp' + i;
      }

      window[key] = val;
      return key;
   }
}
