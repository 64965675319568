/* @flow */

export default class EventMatcher {
	/* ::
		name: string;
	*/

	constructor(name /*: string */) {
		this.name = name;
	}

	matches(e /*: any */) /*: bool */{
		return true;
	}
}
