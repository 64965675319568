/* @flow */

import { EventEmitter } from '@analytics/essence';

export default class Repository extends EventEmitter {

   query() /* : Promise<any> */ {
     return Promise.resolve();
   }

}
