
class AjaxHandlerError extends Error {
	constructor(id) {
		super();
		this.name = 'AjaxHandlerError';
		this.message = `AjaxHandler error caught with id: ${id}`;
	}
}

export default class AjaxHandler {
	constructor(idGetter) {
		this.idGetter = idGetter;
		this.id = idGetter();
		this.validateIsCurrentRequest = this._validateIsCurrentRequest.bind(this);
	}

	_validateIsCurrentRequest(...args) {
		if (this.id !== this.idGetter()) {
			throw new AjaxHandlerError(this.id);
		}

		// pass through
		return args;
	}

	handleCanceledRequest(e) {
		if (e.name !== 'AjaxHandlerError') {
			throw e;
		}
	}
}
