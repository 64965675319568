/*eslint no-console:0*/
const ieRegExp = new RegExp('Trident\\/([0-9]{1,}[\\.0-9]{0,})');
const edgeRegExp = new RegExp('Edge\\/([0-9]{1,}[\\.0-9]{0,})');

const memoize = (fn) => {
	let cache = {};
	return (...args) => {
		let n = args[0];  // just taking one argument here
		if (n in cache) {
			return cache[n];
		}
		else {
			let result = fn(n);
			cache[n] = result;
			return result;
		}
	};
};

class BrowserUtil {

	constructor() {
		this.isChrome = memoize(this.isChrome.bind(this));
		this.isFirefox = memoize(this.isFirefox.bind(this));
		this.isSafari = memoize(this.isSafari.bind(this));
		this.isOpera = memoize(this.isOpera.bind(this));
		this.isIE = memoize(this.isIE.bind(this));
		this.isEdge = memoize(this.isEdge.bind(this));
		this.ieVersion = memoize(this.ieVersion.bind(this));
		this.isMobileBrowser = memoize(this.isMobileBrowser.bind(this));
	}

	get isIEOrEdge() {
		if (this.navigatorIsUndefined()) { return false; }
		return this.isIE() || this.isEdge();
	}

	tagBodyForIE() {
		if (document && document.body && this.isIE()) {
			document.body.classList.add('ie', 'ie' + this.ieVersion());
		}
		if (document && document.body && this.isEdge()) {
			document.body.classList.add('edge', 'ie' + this.ieVersion());
		}
	}

	isChrome() {
		if (this.navigatorIsUndefined()) { return false; }
		let isChrome = navigator.userAgent.indexOf('Chrome') > -1;
		if (this.isOpera() && isChrome) { isChrome = false; }
		if (this.isEdge() && isChrome) { isChrome = false; }
		return isChrome;
	}

	isFirefox() {
		if (this.navigatorIsUndefined()) { return false; }
		return navigator.userAgent.indexOf('Firefox') > -1;
	}

	isSafari() {
		if (this.navigatorIsUndefined()) { return false; }
		let isSafari = navigator.userAgent.indexOf('Safari') > -1;
		if (this.isChrome() && isSafari) { isSafari = false; }
		if (this.isEdge() && isSafari) { isSafari = false; }
		return isSafari;
	}

	isOpera() {
		if (this.navigatorIsUndefined()) { return false; }
		return navigator.userAgent.toLowerCase().indexOf('op') > -1;
	}

	isIE() {
		if (this.navigatorIsUndefined()) { return false; }
		return ieRegExp.test(navigator.userAgent);
	}

	isEdge() {
		if (this.navigatorIsUndefined()) { return false; }
		return edgeRegExp.test(navigator.userAgent);
	}

	isMobileBrowser() {
		if (this.navigatorIsUndefined()) { return false; }
		return navigator.userAgent.match(/Android/i) ||
			navigator.userAgent.match(/webOS/i) ||
			navigator.userAgent.match(/iPhone/i) ||
			navigator.userAgent.match(/iPad/i) ||
			navigator.userAgent.match(/iPod/i) ||
			navigator.userAgent.match(/BlackBerry/i) ||
			navigator.userAgent.match(/Windows Phone/i) ||
			false;
	}

	navigatorIsUndefined() {
		return typeof navigator === 'undefined' || typeof navigator.userAgent === 'undefined';
	}

	ieVersion() {
		if (this.isIE()) {
			let { userAgent } = window.navigator;
			// Test values; Uncomment to check result …
			// IE 10
			// userAgent = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';
			// IE 11
			// userAgent = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';
			// Edge 12 (Spartan)
			// userAgent = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';
			// Edge 13
			// userAgent = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';
			const msie = userAgent.indexOf('MSIE ');
			if (msie > 0) {
				// IE 10 or older => return version number
				return parseInt(userAgent.substring(msie + 5, userAgent.indexOf('.', msie)), 10);
			}

			if (userAgent.includes('Trident/')) {
				// IE 11 => return version number
				const rv = userAgent.indexOf('rv:');
				return parseInt(userAgent.substring(rv + 3, userAgent.indexOf('.', rv)), 10);
			}

			const edge = userAgent.indexOf('Edge/');
			if (edge > 0) {
				// Edge (IE 12+) => return version number
				return parseInt(userAgent.substring(edge + 5, userAgent.indexOf('.', edge)), 10);
			}

			// other browser
			return false;
		}
		return -1;
	}

	//for debugging - make sure the browser info is coming through right for each browser
	logAllBrowserInfo() {
		console.log('this.isChrome(): ', this.isChrome());
		console.log('this.isFirefox(): ', this.isFirefox());
		console.log('this.isEdge(): ', this.isEdge());
		console.log('this.isIE(): ', this.isIE());
		console.log('this.isSafari(): ', this.isSafari());
		console.log('this.isOpera(): ', this.isOpera());
		console.log('this.ieVersion(): ', this.ieVersion());
	}

}

export default new BrowserUtil();
