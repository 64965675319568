let queryCount = 0;

export default function elementQuerySelectorAll(element, selectors, castToArray = false) {
    let result;
    if (/(^\s*|,\s*)>/.test(selectors)) {
        let removeId = false;
        if (!element.id) {
            element.id = 'ID_' + new Date().getTime() + '_' + queryCount++;
            removeId = true;
        }
        selectors = selectors.replace(/(^\s*|,\s*)>/g, '$1#' + element.id + ' >');
        result = document.querySelectorAll(selectors);
        if (removeId) {
            element.removeAttribute('id');
        }
    } else {
        result = element.querySelectorAll(selectors);
    }

    if (castToArray && result) {
        result = Array.prototype.slice.call(result);
    } else if (castToArray) {
        result = [];
    }

    return result;
}
