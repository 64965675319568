/* @flow */
import Entity from './Entity';
import EntityProvider from './EntityProvider';
import _ from './_';
import EntityCollection from './EntityCollection';

export default class EntityCache extends EntityProvider {

	/*::
		_caches: Map<Class<Entity>, Map<string, Entity>>;
		_collections: Object;
		_filteredCollections: Object;
	*/

	constructor(opts = {}) {
		super();
		this._caches = new Map();
		this._collections = {};
		this._filteredCollections = {};
		this._opts = Object.assign({
			createCollection: collectionOrQuery => new EntityCollection(collectionOrQuery),
		}, opts);
	}

	addCollection(name /*: string */, collectionOrQuery /*: any */) {
		let collection;
		if (_.isFunction(collectionOrQuery)) {
			collection = this._opts.createCollection(collectionOrQuery);
		} else if (collectionOrQuery instanceof EntityCollection) {
			collection = collectionOrQuery;
		} else {
			throw new Error(`EntityCache.addCollection: '${name}': collectionOrQuery parameter should be a query function or an instance of EntityCollection.`);
		}
		/* If the collection is already defined, we'll ignore it */
		this._addIfNotPresent(name, collection);
	}

	addCombinedCollection(name /*: string */, definition /*: Object */) {
		let baseCollections = definition.collections.map(collectionName => this.getCollection(collectionName));
		let collection = EntityCollection.combinedCollection(baseCollections, definition);
		this._addIfNotPresent(name, collection);
	}

	getCollection(name /*: string */) /*: EntityCollection */ {
		if (!this._collections[name]) {
			throw new Error(`Collection '${name}' has not been defined.`);
		}
		return this._collections[name];
	}

	when(...names /*: Array<string> */) /*: Promise */ {
		return Promise.all(names.map(name => this.getCollection(name).load(this))).then(() => names.map(name => this.getCollection(name).items));
	}

	add(entity /*: Entity */) {
		if (!(entity instanceof Entity)) { throw new Error('You can only add entities to the cache.'); }
		// If this entity has been seen before, we can just exit,
		// this is important for filtered EntityCollections that
		// might add/update something that is also in the main collection
		// In that case we don't want to call update, since the update
		// or add has already been applied and it is just a waste of cycles
		if (entity.__cached__) { return; }

		let type = entity.constructor;
		let cache = this._caches.get(type);
		if (!cache) {
			cache = new Map();
			this._caches.set(type, cache);
		}
		if (cache.has(entity.id)) {
			this.update(entity);
		} else {
			cache.set(entity.id, entity);
		}

		// Some definitions contain old ids, such as: vcm-2812-298599-9612-1-filter-0-0 for a segment.
		// Those entities have new ids, but have the old legacyId as a fields on them.
		// When hydrating the old definitions which reference the old ids, they are never found.
		// By storing the old id in the cache with the new entity, we can properly hydrate the old definitions.
		if (entity.legacyId) {
			if (cache.has(entity.legacyId)) {
				this.update(entity);
			} else {
				cache.set(entity.legacyId, entity);
			}
		}

		entity.__cached__ = true;
	}

	remove(entity /*: Entity */) {
		if (!(entity instanceof Entity)) { throw new Error('You can only delete entities from the cache.'); }
		let cache = this._caches.get(entity.constructor);
		if (cache) {
			return cache.delete(entity.id);
		}
		return false;
	}

	has(type /*: any */, id /*: string */) /*: boolean */ {
		let cache = this._caches.get(type);
		if (cache) {
			return cache.has(id);
		}
		return false;
	}

	get/*::<T:Entity>*/(type /*: Class<T> */, id /*: string */)  /*: T */ {
		let cache = this._caches.get(type);
		if (cache) {
			let entity = cache.get(id);
			if (entity) {
				return entity;
			}
		}
		const error = new Error(`Entity is not in the cache: ${type.name}, id='${id}'.`);
		error.code = 'ENTITY_NOT_IN_CACHE';
		throw error;
	}

	update(entity /*: Entity*/) {
		if (this.has(entity.constructor, entity.id)) {
			let cachedEntity = this.get(entity.constructor, entity.id);
			cachedEntity.update(entity);
		} else {
			this.add(entity);
		}
	}

	// Ensures an item exists in the provided cache collections, if it is already there it won't be added again
	addToCollections(entity, ...collections) {
		collections.forEach((collectionName) => {
			let collection = this.getCollection(collectionName);
			// Get the version of the entity that is in the cache, so that it will be updated automatically
			let item = this.get(entity.constructor, entity.id);
			// Add the item to the collection - collections automatically dedup based on id
			collection.add(item);
		});
	}

	removeFromCollections(entity, ...collections) {
		collections.forEach((collectionName) => {
			let collection = this.getCollection(collectionName);
			collection.remove(entity);
		});
	}

	_addIfNotPresent(name /*: string */, collection /*: EntityCollection */) {
		if (_.isUndefined(this._collections[name])) {
			this._collections[name] = collection;
		}
	}


}
